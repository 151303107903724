
/*   -=========== Forms ===========-   */

// Base

input,
textarea {
  font: inherit;
}

.form {
  width: 750px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  @media $mobile {
    @media $not-small-mobile {
      width: calc(100% - 20px);
    }
  }

  @media $small-mobile {
    width: 100%;
  }

  span.required {
    font-family: $text-font;
  }
}

.inp,
.textarea {
  box-sizing: border-box;
  width: 100%;
  padding: 1.13em 1.5em;
  line-height: 1.25em;
  color: lighten($text-color, 5);
  border: 2px dotted $blue;
  background: transparent;
  transition: all .25s ease;
  font-size: 1.9rem;

  &:focus {
    color: $text-color;
    border-color: $white;
  }

  .form-field.error > & {
    border-color: $warning-color;
    color: $warning-color;
  }
}

.textarea {
  min-height: 14.25em;
}

.checkbox {
  padding-left: 92px;
  position: relative;
  vertical-align: top;
  min-height: 70px;

  input {
    display: none;
  }

  &:before {
    content: '';
    display: block;
    width: 70px; height: 70px;
    box-sizing: border-box;
    position: absolute;
    left: 0; top: 0;
    border: 2px dotted $blue;
  }

  &:after {
    content: '';
    display: block;
    width: 70px; height: 70px;
    position: absolute;
    left: 0; top: 0;
    background: url(/i/check.png) no-repeat 50%;
    opacity: 0;
    transition: opacity .25s ease;
  }

  &.checked {
    &:after {
      opacity: 1;
    }
  }
}

.checkbox-label {
  display: block;
  font-size: 1.4rem;
  line-height: 1.5;
  color: #9d9e9f;
  margin-top: -3px;
  max-width: 490px;
}

.fileinp {
  @extend .btn, .blue-dotted-btn;
  width: 100%;
  line-height: 70px;
  font-size: 1.8rem;
  color: $white;
  background: rgba($white, .25);
  position: relative;

  input {
    display: none;
  }

  .chosen-file {
    position: absolute;
    left: 0; top: 100%;
    white-space: nowrap;
    font-size: 1.2rem;
    line-height: 1.5;
    margin-top: 5px;
  }
}

.form-row {
  width: 100%;
  margin-bottom: 35px;
}

.form-label {
  font-family: $caption-font;
  font-size: 1.9rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;

  @media $not-small-mobile {
    white-space: nowrap;
  }
}

.form-label-hint {
  font-family: $text-font;
  font-size: 1.4rem;
  font-weight: normal;
}

.form-field {
  position: relative;

  .two-columns-form & {
    float: right;
  }

  .errorMessage {
    display: none;
    font-size: 1.2rem;
    line-height: 1em;
    padding: 4px 0 3px;
    text-align: center;
    background: $warning-color;
    color: $white;
    position: absolute;
    left: 0; bottom: 0;
    transform: translateY(100%);
    width: 100%;
  }

  .checkbox + .errorMessage {
    background: none;
    color: $red;
    font-weight: bold;
  }

  &.error {
    .errorMessage {
      display: block;
    }
  }
}

/* @media $not-small-mobile { */
@media $not-landscape-mobile {
  .form-row.third,
  .form-row.half {
    box-sizing: border-box;

    .form-label {
      text-align: left;
    }

    .form-label-hint {
      display: block;
    }
  }

  .form-row.third {
    width: 33.33%;
    padding-right: 7%;
  }

  .form-row.half {
    width: calc(50% - 15px);

    + .form-row.half {
      margin-left: 30px;
    }
  }
}

.form-submit {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.form .download-btn {
  @extend .btn, .blue-dotted-btn;
  width: 100%;
  line-height: 70px;
  font-size: 1.8rem;
  color: $white;
  background: rgba($white, .25);
  position: relative;
}

.form .submit-btn {
  @extend .btn, .blue-dotted-btn;
  padding: 0 70px;
  line-height: 70px;
  font-size: 2rem;
  font-weight: bold;
  font-family: $caption-font;
  color: $white;
  background: rgba($blue, .25);
  position: relative;
}

.form-label-tooltip {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-left: 5px;

  .tooltip--anchor {
    display: block;
    width: 33px; height: 32px;
    background: url(/i/tooltip-anchor.png) no-repeat 50%;
    margin: -10px 0;
    cursor: pointer;
  }

  .tooltip {
    display: none;
    position: absolute;
    left: 100%; top: -30px;
    z-index: 10;
    background: $blue;
    font-family: $text-font;
    font-size: 1.5rem;
    font-weight: normal;
    text-align: left;
    padding: 20px 45px 15px 20px;
    margin-left: 15px;
    width: 330px;
    white-space: normal;

    @media $mobile {
      width: 200px;
    }

    @media $landscape-mobile {
    /* @media $small-mobile { */
      position: fixed;
      width: calc(100vw - 20px);
      left: 10px; top: 50%;
      transform: translateY(-50%);
      margin-left: 0;
      box-sizing: border-box;
    }

    p + p {
      margin-top: 1em;
    }
  }

  .tooltip--close {
    right: 10px; top: 10px;
  }
}