
/*   -=========== Winners ===========-   */

.winner--name {
  font-family: $caption-font;
  font-weight: bold;
  line-height: 1.2;
  text-transform: uppercase;
  white-space: nowrap;
  position: absolute;
  top: 100%; left: 50%;
  transform: translateX(-50%);
}

.winners--page-caption {
  font-size: 5rem;
  font-weight: bold;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 70px;
  padding-top: 50px;

  @media $mobile {
    font-size: 3.8rem;
    margin-bottom: 100px;
  }

  @media $small-mobile {
    font-size: 2.7rem;
    margin-bottom: 50px;
  }
}

.winners-section-1 {
  position: relative;
  background: url(/i/winners-bg-1.jpg) no-repeat 50% / cover;
  padding-top: $header-height + 20px;
  padding-bottom: 5%;
  min-height: 960px;
  box-sizing: border-box;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    width: 100%; height: 76px;
    background: url(/i/summer/winners-top-separator.png) no-repeat 50% 0;
    position: absolute;
    left: 0; top: 0;
  }

  @media $handheld {
    min-height: 880px;
  }

  @media $small-mobile {
    padding-top: $mobile-header-height + 10px;
  }

  .winners-caption {
    max-width: 885px;
    width: 100%;
    padding-bottom: 11%;
    background: url(/i/winners-caption-1.png) no-repeat 50% / contain;
    margin: 0 auto;
    font-size: 0;
    transform: translateX(-5%);

    @media $small-mobile {
      padding-bottom: 24%;
      margin-bottom: 10px;
    }
  }

  .winner {
    text-decoration: none;
  }

  .winners--places {
    max-width: 1230px;
    margin-left: auto;
    margin-right: auto;

    @media $not-mobile {
      display: flex;
      justify-content: center;
    }

    @media $tablet {
      width: calc(100% - 40px);
    }
  }

  .place {
    flex: 1;
    text-align: center;

    + .place {
      @media $not-mobile {
        margin-left: 30px;
      }

      @media $mobile {
        margin-top: 50px;
      }
    }

    .winner {
      display: inline-block;
      position: relative;
    }

    .winner--name {
      position: static;
      font-size: 3.2rem;
      transform: none;

      @media $mobile {
        font-size: 2.8rem;
      }
    }

    .place-text {
      text-align: center;
      font-size: 1.7rem;
      line-height: 1.2;

      img {
        margin-top: 5px;
      }
    }
  }

  .place-1 {
    .winner:before {
      content: '';
      display: block;
      width: 172px; height: 155px;
      position: absolute;
      left: -25%; top: -20%;
      background: url(/i/place-1st-icon.png) no-repeat 50% / contain;
      pointer-events: none;

      @media $notebook {
        left: -15%;
      }

      @media $handheld {
        left: -5%;
      }
    }

    .place-text {
      margin-left: -70px;
      margin-right: -70px;

      img {
        margin-left: -40px;
        margin-top: 10px;
      }
    }
  }

  .place-2 {
    .winner:before {
      content: '';
      display: block;
      width: 172px; height: 155px;
      position: absolute;
      left: -15%; top: -20%;
      background: url(/i/place-2nd-icon.png) no-repeat 50% / contain;
      pointer-events: none;
    }
  }

  .place-3 {
    .winner:before {
      content: '';
      display: block;
      width: 172px; height: 155px;
      position: absolute;
      left: -15%; top: -20%;
      background: url(/i/place-3rd-icon.png) no-repeat 50% / contain;
      pointer-events: none;
    }
  }
}

.winners-section-2 {
  position: relative;
  background: url(/i/winners-bg-2.jpg) no-repeat 50% / cover;
  padding-top: $header-height + 20px;
  padding-bottom: 5%;
  min-height: 960px;
  box-sizing: border-box;
  overflow: hidden;

  @media $handheld {
    min-height: 880px;
  }

  @media $small-mobile {
    min-height: 580px;
  }

  .winners-caption {
    max-width: 1105px;
    width: 100%;
    padding-bottom: 22%;
    background: url(/i/winners-caption-2.png) no-repeat 50% / contain;
    margin: 0 auto;
    font-size: 0;
    transform: translateX(-5%);

    @media $small-mobile {
      padding-bottom: 37%;
    }
  }

  .winner {
    position: relative;
  }

  .winner--name {
    font-size: 4rem;

    @media $small-mobile {
      font-size: 3rem;
    }
  }
}

.winners-section-3 {
  position: relative;
  background: url(/i/winners-bg-3.jpg) no-repeat 50% / cover;
  padding-top: $header-height + 20px;
  padding-bottom: 5%;
  min-height: 960px;
  box-sizing: border-box;
  overflow: hidden;

  @media $handheld {
    min-height: 880px;
  }

  .winners-caption {
    max-width: 989px;
    width: 100%;
    padding-bottom: 14%;
    background: url(/i/winners-caption-3.png) no-repeat 50% / contain;
    margin: 0 auto;
    font-size: 0;
    transform: translateX(-5%);

    @media $small-mobile {
      padding-bottom: 28%;
      position: relative;
      z-index: 11;
    }
  }

  .place-1 {
    display: inline-block;
    position: relative;
    z-index: 10;
    margin-top: -2%;
    transform: translateX(-15%);

    @media $mobile {
      transform: translateX(-60%);
    }

    @media $small-mobile {
      transform: none;
      padding-top: 120px;
    }

    &:before {
      content: '';
      display: block;
      width: 61%; height: 61%;
      position: absolute;
      left: -26%; top: -3%;
      background: url(/i/place-1-icon.png) no-repeat 50% / contain;
      pointer-events: none;
    }

    &:after {
      content: '';
      display: block;
      width: 136%;
      padding-bottom: 96%;
      background: url(/i/winner-prize-3.png) no-repeat 50% / contain;
      position: absolute;
      left: 60%; top: -7%;
      z-index: -1;

      @media $small-mobile {
        top: -15%; right: 0; left: auto;
      }
    }

    .winner--name {
      font-size: 3.9rem;

      @media $mobile {
        font-size: 3rem;
      }
    }
  }

  .place-2 {
    width: 27%;
    padding-bottom: 19%;
    position: absolute;
    bottom: 8%; right: 57%;
    z-index: 5;

    @media only screen and (max-width: 1600px) {
      right: auto; left: 5%;
    }

    @media $small-mobile {
      width: 100%;
      position: relative;
      bottom: auto; right: auto;
      padding-top: 190px;
      margin-top: 70px;
    }

    &:before {
      content: '';
      display: block;
      width: 60%;
      padding-bottom: 53%;
      background: url(/i/winner-prize-4.png) no-repeat 50% / contain;
      position: absolute;
      left: -23%; top: -14%;
      z-index: -1;

      @media $small-mobile {
        display: none;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 100%; height: 100%;
      background: url(/i/place-2-text-bg.png) no-repeat 50% / contain;
      position: absolute;
      left: 0; top: 0;

      @media $small-mobile {
        display: none;
      }
    }

    .place-text {
      position: absolute;
      left: 20%; top: 52%;
      z-index: 1;
      box-sizing: border-box;
      width: 100%;
      color: $white;
      font-family: $caption-font;
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1.2;
      text-transform: uppercase;

      @media $handheld {
        font-size: 1.6rem;
      }

      @media $small-mobile {
        position: static;
        padding: 0 20px;
        text-align: center;
      }
    }

    .winner {
      position: absolute;
      left: 330px; bottom: 0;
      z-index: 1;

      @media $tablet {
        left: 260px;
      }

      @media $small-mobile {
        bottom: auto; top: 0; left: 50%;
        transform: translateX(-50%);

        &:before {
          content: '';
          display: block;
          width: 80px; height: 80px;
          background: url(/i/place-2-medium-icon.png) no-repeat 50% / contain;
          position: absolute;
          left: -20px; top: -10px;
        }
      }

      img {
        max-width: none;

        @media $mobile {
          max-width: 160px;
        }
      }
    }

    .winner--name {
      font-size: 3.2rem;

      @media $handheld {
        font-size: 2.6rem;
      }

      @media $mobile {
        font-size: 2rem;
      }
    }
  }

  .place-3 {
    width: 27%;
    padding-bottom: 19%;
    position: absolute;
    bottom: 8%; left: 52%;
    z-index: 5;

    @media $mobile {
      bottom: 33%; left: 44%;
    }

    @media $small-mobile {
      width: 100%;
      position: relative;
      bottom: auto; left: auto;
      padding-top: 190px;
      margin-top: -20px;
    }

    &:before {
      content: '';
      display: block;
      width: 61%;
      padding-bottom: 58%;
      background: url(/i/winner-prize-5.png) no-repeat 50% / contain;
      position: absolute;
      left: 47%; top: -16%;
      z-index: -1;

      @media $small-mobile {
        display: none;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 100%; height: 100%;
      background: url(/i/place-3-text-bg.png) no-repeat 50% / contain;
      position: absolute;
      left: 0; top: 0;

      @media $small-mobile {
        display: none;
      }
    }

    .place-text {
      position: absolute;
      left: 20%; top: 52%;
      z-index: 1;
      box-sizing: border-box;
      width: 100%;
      color: $white;
      font-family: $caption-font;
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1.2;
      text-transform: uppercase;

      @media $handheld {
        font-size: 1.6rem;
      }

      @media $small-mobile {
        position: static;
        padding: 0 20px;
        text-align: center;
      }
    }

    .winner {
      position: absolute;
      left: 330px; bottom: 0;
      z-index: 1;

      @media $tablet {
        left: 260px;
      }

      @media $small-mobile {
        bottom: auto; top: 0; left: 50%;
        transform: translateX(-50%);

        &:before {
          content: '';
          display: block;
          width: 80px; height: 80px;
          background: url(/i/place-3-medium-icon.png) no-repeat 50% / contain;
          position: absolute;
          left: -20px; top: -10px;
        }
      }

      img {
        max-width: none;

        @media $mobile {
          max-width: 160px;
        }
      }
    }

    .winner--name {
      font-size: 3.2rem;

      @media $handheld {
        font-size: 2.6rem;
      }

      @media $mobile {
        font-size: 2rem;
      }
    }
  }
}

.winners-section-4 {
  position: relative;
  background: url(/i/winners-bg-4.jpg) no-repeat 50% / cover;
  padding-top: 50px;
  padding-bottom: 5%;
  min-height: 960px;
  box-sizing: border-box;
  overflow: hidden;

  @media $handheld {
    min-height: 880px;
  }

  .winners-caption {
    max-width: 1184px;
    width: 100%;
    padding-bottom: 17%;
    background: url(/i/winners-caption-4.png) no-repeat 50% / contain;
    margin: 0 auto;
    font-size: 0;
    transform: translateX(-5%);

    @media $small-mobile {
      padding-bottom: 28%;
    }
  }

  .place-1 {
    display: inline-block;
    position: relative;
    z-index: 10;
    margin-top: -2%;
    transform: translateX(-15%);

    @media $mobile {
      transform: translateX(-50%);
    }

    @media $small-mobile {
      transform: none;
      padding-top: 120px;
    }

    .place-text {
      position: absolute;
      left: 100%; top: 0;
      text-transform: uppercase;
      font-family: $caption-font;
      font-size: 2.4rem;
      font-weight: bold;
      line-height: 1.2;
      text-align: left;
      white-space: nowrap;
      margin-left: 10px;

      @media $small-mobile {
        right: 0; left: auto;
        font-size: 1.3rem;
        width: 65%;
      }

      &:before {
        content: '';
        display: block;
        width: 1061px;
        padding-bottom: 85%;
        background: url(/i/winners-text-bg.png) no-repeat 50% / contain;
        position: absolute;
        top: -21%; left: 10%;
        transform: translateX(-50%);
        z-index: -1;
      }
    }

    &:before {
      content: '';
      display: block;
      width: 61%; height: 61%;
      position: absolute;
      left: -26%; top: -3%;
      background: url(/i/place-1-icon.png) no-repeat 50% / contain;
      pointer-events: none;

      @media $small-mobile {
        width: 59%;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 136%;
      padding-bottom: 96%;
      background: url(/i/winner-prize-6.png) no-repeat 50% / contain;
      position: absolute;
      left: -108%; top: 9%;
      z-index: -1;
    }

    .winner--name {
      font-size: 3.9rem;

      @media $mobile {
        font-size: 3rem;
      }
    }
  }

  .place-2 {
    width: 27%;
    padding-bottom: 19%;
    position: absolute;
    bottom: 8%; right: 57%;
    z-index: 5;

    @media only screen and (max-width: 1600px) {
      right: auto; left: 5%;
    }

    @media $mobile {
      bottom: 12%;
      z-index: 6;
    }

    @media $small-mobile {
      width: 100%;
      position: relative;
      bottom: auto; right: auto;
      padding-top: 190px;
      margin-top: 70px;
    }

    &:before {
      content: '';
      display: block;
      width: 146%;
      padding-bottom: 89%;
      background: url(/i/winner-prize-7.png) no-repeat 50% / contain;
      position: absolute;
      left: -9%; top: -24%;
      z-index: -1;

      @media $small-mobile {
        display: none;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 100%; height: 100%;
      background: url(/i/place-2-text-bg.png) no-repeat 50% / contain;
      position: absolute;
      left: 0; top: 0;

      @media $small-mobile {
        display: none;
      }
    }

    .place-text {
      position: absolute;
      left: 15%; top: 52%;
      z-index: 1;
      box-sizing: border-box;
      width: 100%;
      color: $white;
      font-family: $caption-font;
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1.2;
      text-transform: uppercase;

      @media $handheld {
        font-size: 1.6rem;
      }

      @media $small-mobile {
        position: static;
        padding: 0 20px;
        text-align: center;
      }
    }

    .winner {
      position: absolute;
      left: 330px; bottom: 0;
      z-index: 1;

      @media $tablet {
        left: 260px; bottom: -27px;
      }

      @media $small-mobile {
        bottom: auto; top: 0; left: 50%;
        transform: translateX(-50%);

        &:before {
          content: '';
          display: block;
          width: 80px; height: 80px;
          background: url(/i/place-2-medium-icon.png) no-repeat 50% / contain;
          position: absolute;
          left: -20px; top: -10px;
        }
      }

      img {
        max-width: none;

        @media $mobile {
          max-width: 160px;
        }
      }
    }

    .winner--name {
      font-size: 3.2rem;

      @media $handheld {
        font-size: 2.6rem;
      }

      @media $mobile {
        font-size: 2rem;
      }
    }
  }

  .place-3 {
    width: 27%;
    padding-bottom: 19%;
    position: absolute;
    bottom: 8%; left: 52%;
    z-index: 5;

    @media $mobile {
      left: 42%; bottom: 30%;
    }

    @media $small-mobile {
      width: 100%;
      position: relative;
      bottom: auto; left: auto;
      padding-top: 190px;
      margin-top: -20px;
    }

    &:before {
      content: '';
      display: block;
      width: 118%;
      padding-bottom: 133%;
      background: url(/i/winner-prize-8.png) no-repeat 50% / contain;
      position: absolute;
      left: 5%; top: -67%;
      z-index: -1;

      @media $notebook {
        top: -56%;
      }

      @media $handheld {
        top: -52%;
      }

      @media $small-mobile {
        display: none;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 100%; height: 100%;
      background: url(/i/place-3-text-bg.png) no-repeat 50% / contain;
      position: absolute;
      left: 0; top: 0;

      @media $small-mobile {
        display: none;
      }
    }

    .place-text {
      position: absolute;
      left: 15%; top: 52%;
      z-index: 1;
      box-sizing: border-box;
      width: 100%;
      color: $white;
      font-family: $caption-font;
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1.2;
      text-transform: uppercase;

      @media $handheld {
        font-size: 1.6rem;
      }

      @media $small-mobile {
        position: static;
        padding: 0 20px;
        text-align: center;
      }
    }

    .winner {
      position: absolute;
      left: 330px; bottom: 0;
      z-index: 1;

      @media $tablet {
        left: 271px; bottom: -35px;
      }

      @media $small-mobile {
        bottom: auto; top: 0; left: 50%;
        transform: translateX(-50%);

        &:before {
          content: '';
          display: block;
          width: 80px; height: 80px;
          background: url(/i/place-3-medium-icon.png) no-repeat 50% / contain;
          position: absolute;
          left: -20px; top: -10px;
        }
      }

      img {
        max-width: none;

        @media $mobile {
          max-width: 160px;
        }
      }
    }

    .winner--name {
      font-size: 3.2rem;

      @media $handheld {
        font-size: 2.6rem;
      }

      @media $mobile {
        font-size: 2rem;
      }
    }
  }
}

.winners-section-5 {
  position: relative;
  background: url(/i/winners-bg-5.jpg) no-repeat 50% / cover;
  padding-top: $header-height + 20px;
  padding-bottom: 5%;
  min-height: 960px;
  box-sizing: border-box;
  overflow: hidden;

  @media $handheld {
    min-height: 880px;
  }

  @media $small-mobile {
    min-height: 580px;
  }

  .winners-caption {
    max-width: 1184px;
    width: 100%;
    padding-bottom: 17%;
    background: url(/i/winners-caption-5.png) no-repeat 50% / contain;
    margin: 0 auto;
    font-size: 0;
    transform: translateX(-5%);
    margin-bottom: -3%;

    @media $small-mobile {
      padding-bottom: 28%;
    }
  }

  .winner {
    position: relative;

    img {
      position: relative;
      z-index: 5;
    }
  }

  .winner--name {
    font-size: 4rem;

    @media $small-mobile {
      font-size: 3rem;
    }

    &:before {
      content: '';
      display: block;
      width: 1061px;
      padding-bottom: 160%;
      background: url(/i/winners-text-bg.png) no-repeat 50% / contain;
      position: absolute;
      top: -400%; left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }
  }

  .winner--content {
    position: absolute;
    top: 100%; left: 50%;
    transform: translateX(-50%);
    font-size: 1.8rem;
    line-height: 1.5;
    display: inline-block;

    @media $handheld {
      font-size: 1.6rem;
    }

    @media $small-mobile {
      font-size: 1.3rem;
      width: 300px;
      white-space: normal;
    }
  }
}

.winners-section-6 {
  position: relative;
  background: url(/i/winners-bg-6.jpg) no-repeat 50% / cover;
  padding-top: $header-height + 20px;
  padding-bottom: 5%;
  min-height: 960px;
  box-sizing: border-box;
  overflow: hidden;

  @media $handheld {
    min-height: 880px;
  }

  @media $small-mobile {
    min-height: 580px;
    padding-top: $mobile-header-height + 10px;
  }

  .winners-caption {
    max-width: 1087px;
    width: 100%;
    padding-bottom: 19%;
    background: url(/i/winners-caption-6.png) no-repeat 50% / contain;
    margin: 0 auto;
    font-size: 0;
    transform: translateX(-5%);
    margin-bottom: -5%;

    @media $small-mobile {
      padding-bottom: 33%;
    }
  }

  .winner {
    position: relative;

    img {
      position: relative;
      z-index: 5;
    }
  }

  .winner--name {
    font-size: 4rem;

    @media $small-mobile {
      font-size: 3rem;
    }

    &:before {
      content: '';
      display: block;
      width: 1061px;
      padding-bottom: 160%;
      background: url(/i/winners-text-bg-2.png) no-repeat 50% / contain;
      position: absolute;
      top: -300%; left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }
  }

  .winner--content {
    position: absolute;
    top: 100%; left: 50%;
    transform: translateX(-50%);
    font-size: 1.8rem;
    line-height: 1.5;
    display: inline-block;

    @media $handheld {
      font-size: 1.6rem;
    }

    @media $small-mobile {
      font-size: 1.3rem;
    }
  }

  .dot {
    display: inline-block;
    vertical-align: middle;
    width: 4px; height: 4px;
    border-radius: 50%;
    background: $white;
    margin: 0 10px;
  }
}

.common-font {
  font-family: sans-serif;
}

.all-winners-link {
  text-align: center;
  padding: 20px 0;

  a {
    @extend .btn, .blue-btn, .white-dotted-btn, .h62btn, .p45btn, .s26, .bb, .uppercase, .caption-font;

    @media $small-mobile {
      font-size: 16px;
    }
  }
}