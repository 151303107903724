
/*   -=========== Participant ===========-   */

.participant-section {
  position: relative;
  padding-top: $header-height;
  background: #3c8fcb url(/i/bg-8.jpg) no-repeat 0 0 / 100% auto;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0; bottom: 0;
    width: 100%; height: 300px;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  }

  .wrapper {
    position: relative;
  }

  .back-link {
    position: absolute;
    left: 0; top: 30px;
    color: $white;

    @media only screen and (max-width: $wrapper-width + 20px) {
      left: 10px;
    }

    &:before {
      filter: brightness(10);
    }
  }
}

.participant-section--left-bg {
  background: url(/i/snow-left.png) no-repeat 0 0 / contain;
  width: 50%;
  height: 100%;
  position: absolute;
  left: 0; top: 0;
  margin-left: -10%;
}

.participant-section--right-bg {
  background: url(/i/snow-right.png) no-repeat 0 0 / contain;
  width: 30%;
  height: 100%;
  position: absolute;
  right: 0; top: 0;
  margin-right: -10%;
}

.participant-section--pic {
  text-align: center;
  margin-top: 60px;
  position: relative;
  line-height: 0;

  /* &:after {
    content: '';
    display: block;
    width: width('i/sign-border.svg');
    height: height('i/sign-border.svg');
    background: url(/i/sign-border.svg) no-repeat 50% / contain;
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
  } */

  /* span {
    display: inline-block;
    width: width('i/sign-clip.svg');
    height: height('i/sign-clip.svg');
    clip-path: url(#clip-path);
    position: relative;
    overflow: hidden;
  } */

  span {
    display: inline-block;
    position: relative;
    width: width('i/round-sign.png');
    height: height('i/round-sign.png');
    overflow: hidden;
    border-radius: 50%;

    &:after {
      content: '';
      width: 100%; height: 100%;
      display: inline-block;
      background: url(/i/round-sign.png) no-repeat 50% / contain;
      position: absolute;
      left: 0; top: 0;
    }
  }

  img {
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    max-width: none;
  }
}

.participant-section--name {
  font-family: $caption-font;
  font-size: 3.6rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 25px;
  background: url(/i/name-border.png) no-repeat 50% 100%;
  min-width: width('i/name-border.png');
  margin-bottom: 12px;
  margin-top: -15px;
  position: relative;
}

.participant-section--age {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.participant-section--text {
  box-sizing: border-box;
  width: 790px;
  max-width: 100%;
  padding: 0 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;

  @media $mobile {
    width: 100%;
  }
}

.participant-section {
  &:after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 19%;
    background: url(/i/participant-divider.png) no-repeat 50% 100%;
    position: absolute;
    bottom: 0; left: 50%;
    transform: translate(-50%, 50%);
    pointer-events: none;
  }

  .stages-line--caption {
    text-align: center;
    color: $black;
    font-family: $caption-font;
    font-size: 3.6rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
  }

  .stages-line {
    position: relative;
    margin-top: 100px;
    z-index: 10;

    @media $small-mobile {
      margin-top: 20px;
    }
  }
}

.video-section {
  padding-bottom: 42.5%;
  position: relative;

  iframe {
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
  }

  .video-warning {
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center;
    font-size: 2rem;
    font-family: $caption-font;
    text-transform: uppercase;
  }
}

.social-section--share,
.news--share {
  float: left;
  background: rgba($black, .2);
  padding: 0 12px;
  line-height: 50px;
  margin-bottom: 15px;

  @media $small-mobile {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-bottom: 25px;
  }

  + * {
    clear: left;
  }

  .ya-share2 {
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;

    @media $small-mobile {
      margin-left: 10px;
    }

    ul {
      display: flex;

      li {
        list-style: none;

        + li {
          margin-left: 20px;
        }

        a {
          display: inline-block;
          vertical-align: middle;
          font-size: 0;
          line-height: 0;
          margin-top: -2px;
          transition: opacity .25s ease;

          &:before {
            display: inline-block;
            vertical-align: middle;
          }

          &:hover {
            opacity: .6;
          }
        }
      }
    }

    .ya-share2__item_service_vkontakte {
      a:before {
        content: url(/i/share-vk.png);
      }
    }

    .ya-share2__item_service_facebook {
      a:before {
        content: url(/i/share-fb.png);
      }
    }

    .ya-share2__item_service_twitter {
      a:before {
        content: url(/i/share-twit.png);
      }
    }

    .ya-share2__item_service_odnoklassniki {
      a:before {
        content: url(/i/share-ok.png);
      }
    }
  }
}

.social-section--share-caption,
.news--share-caption {
  font-size: 1.8rem;

  @media $small-mobile {
    font-size: 1.4rem;
  }
}

.social-section--actions-row {
  text-align: center;
  line-height: 65px;
  position: relative;

  @media $not-mobile {
    .prev-link {
      position: absolute;
      left: 0; top: 50%;
      transform: translateY(-50%);

      @media only screen and (max-width: $wrapper-width + 20px) {
        left: 10px;
      }
    }

    .next-link {
      position: absolute;
      right: 0; top: 50%;
      transform: translateY(-50%);

      @media only screen and (max-width: $wrapper-width + 20px) {
        right: 10px;
      }
    }
  }

  @media $mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.prev-link, .next-link {
  text-decoration: none;
  font-size: 1.8rem;
}

.vote-btn {
  @extend .btn, .white-dotted-btn, .h65btn, .s20, .bb, .uppercase;
  padding: 0 100px 0 20px;
  background: rgba($black, .2);
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 76px; height: 100%;
    border-left: 2px dotted $white;
    position: absolute;
    right: 0; top: 0;
    transition: all .25s ease;
    background: url(/i/heart-icon.png) no-repeat 50%;
    filter: brightness(5);
  }

  &:not(.disabled) {
    &:hover {
      background: $white;

      &:after {
        border-left-color: $blue;
        filter: brightness(1);
      }
    }
  }

  &.disabled {
    opacity: .75;
    cursor: default;

    &:hover {
      color: $white;

      &:before {
        border-color: $white;
      }
    }
  }

  @media $small-mobile {
    line-height: 1.2;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
}

.prev-link {
  display: inline-block;
  color: $white;
  font-size: 1.6rem;
  text-decoration: none;
  padding-left: 70px;
  transition: color .25s ease;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 70px; height: 100%;
    background: url(/i/left-white.png) no-repeat 0 50%;
    position: absolute;
    left: 0; top: 50%;
    transform: translateY(-50%);
    transition: filter .25s ease;
  }

  &:hover {
    color: $white;
    opacity: .65;
  }
}

.next-link {
  display: inline-block;
  color: $white;
  font-size: 1.6rem;
  text-decoration: none;
  padding-right: 70px;
  transition: color .25s ease;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 70px; height: 100%;
    background: url(/i/right-white.png) no-repeat 100% 50%;
    position: absolute;
    right: 0; top: 50%;
    transform: translateY(-50%);
    transition: filter .25s ease;
  }

  &:hover {
    color: $white;
    opacity: .65;
  }
}

@media $small-mobile {
  .prev-link, .next-link {
    line-height: 2.5;
  }
}

.social-section--buttons {
  text-align: center;
  margin-top: 45px;

  @media $small-mobile {
    margin-top: 30px;
  }

  .btn {
    @extend .white-dotted-btn, .h65btn, .s18, .uppercase, .bb;
    width: 372px;
    max-width: 100%;
    padding: 0 10px;
    box-sizing: border-box;

    @media $small-mobile {
      font-size: 1.6rem;
    }

    @media $mobile {
      width: 330px;
    }

    @media $small-mobile {
      width: 100%;
    }

    &:hover {
      background: $white;
    }

    + .btn {
      margin-left: 24px;

      @media $small-mobile {
        margin-left: 0;
        margin-top: 15px;
      }
    }
  }
}

.vote-section {
  @media only screen and (max-height: 870px) {
    .inner {
      margin-top: 130px;
    }
  }

  @media $mobile {
    height: auto;

    .inner {
      position: relative;
      margin-top: 260px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @media $small-mobile {
    .inner {
      margin-top: 100px;
    }
  }
}