
/*   -=========== Menus ===========-   */

// Base

.menu {
  ul, ol, li {
    margin: 0;
    list-style: none;
    user-select: none;
  }

  a {
    text-decoration: none;
  }

  .active > a {
    cursor: default;
  }

  li, a {
    user-select: none;
  }

  a:not([href]) {
    cursor: default;
  }
}

.inline-menu {
  li {
    display: inline-block;
  }
}

.horizontal-menu {
  ul {
    clearfix();
  }

  li {
    float: left;
  }
}

.vertical-menu {
  li {
    display: block;
  }
}

.justify-menu {
  > ul, > ol {
    line-height: 0;
    text-align: justify;

    &:after {
      width: 100%;
      height: 0;
      content: "";
      font-size: 0;
      line-height: 0;
      display: inline-block;
      visibility: hidden;
      overflow: hidden;
    }

    > li {
      display: inline-block;
    }
  }
}

.table-menu {
  ul {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  li {
    display: table-cell;
  }
}


// Breadcrumbs

.breadcrumbs {
  color: #606060;
  margin-top: $header-height;
  margin-bottom: 10px;

  .wrapper {
    padding-left: 20px;
  }

  @media $mobile {
    display: none;

    + * {
      margin-top: $mobile-header-height;
      padding-top: 10px;
    }
  }

  li {
    display: inline-block;
    vertical-align: middle;
    line-height: 42px;
    font-size: 1.2rem;
    font-weight: normal;

    + li {
      padding-left: 25px;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 11px; height: 11px;
        border-right: 1px solid #404040;
        border-top: 1px solid #404040;
        position: absolute;
        left: 8px; top: 50%;
        transform: rotate(45deg) translate(-60%, -50%);
        transform-origin: top left;
      }
    }
  }

  a {
    display: block;
    height: 100%;
    text-decoration: none;
    color: $white;
    transition: color .25s ease;

    &:hover {
      color: $gray;
    }
  }

  &.news-breadcrumbs {
    position: absolute;
    left: 0; top: 0;
    z-index: 10;
    width: 100%;
    color: rgba($white, .7);

    .news-page & {
      color: $gray;

      a {
        color: $black;
      }
    }
  }
}