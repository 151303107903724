
/*   -=========== Structure ===========-   */

.svg-sprite {
  display: none;
}

// Base

body {
  display: table;
  width: 100%;
}

#structure {
  display: table-row;
  height: 100%;
  margin: auto;
  position: relative;
}

.footer {
  position: relative;
}


// Header

.header {
  width: 100%;
  position: fixed;
  top: 0; left: 0;
  z-index: 101;
  background: rgba($black, .7);
  box-sizing: border-box;

  @media $not-mobile {
    height: $header-height;
  }

  @media $mobile {
    height: $mobile-header-height;
  }

  .wrapper {
    position: relative;
    height: 100%;
  }
}

.header--logo-group {
  position: absolute;
  left: 50%; top: 0;
  transform: translateX(-50%);
  height: 100%;

  @media $handheld {
    @media $not-mobile {
      .header--timer + & {
        margin-left: 5%;
      }
    }
  }

  @media $mobile {
    background-size: contain;

    /* @media $not-small-mobile {
      transform: translateX(-100%);
    } */
  }

  @media $small-mobile {
    width: 36%;
    /* margin-left: -8%; */
    white-space: nowrap;
  }
}

.header--logo {
  display: inline-block;
  vertical-align: middle;
  width: width('i/marathon-logo.svg'); height: 100%;
  background: url(/i/marathon-logo.svg) no-repeat 50%;
  background-size: size('i/marathon-logo.svg');
  font-size: 0;

  @media $mobile {
    background-size: contain;
    height: 34px;
  }

  @media $small-mobile {
    width: 50%;
    margin-left: -8%;
  }
}

.header--ripcurl-logo {
  display: inline-block;
  vertical-align: middle;
  width: width('i/ripcurl-logo.svg'); height: 100%;
  background: url(/i/ripcurl-logo.svg) no-repeat 50%;
  background-size: size('i/ripcurl-logo.svg');
  font-size: 0;
  position: relative;
  margin-left: 14px;

  &:before {
    content: '';
    display: block;
    width: width('i/logo-line.svg'); height: 100%;
    background: url(/i/logo-line.svg) no-repeat 50%;
    background-size: size('i/logo-line.svg');
    position: absolute;
    right: 100%; top: 0;
    margin-right: 3px;

    @media $mobile {
      background-size: auto 90%;
      height: 34px;
    }
  }

  @media $mobile {
    background-size: contain;
  }

  @media $small-mobile {
    width: 50%;
  }
}

.header--menu {
  position: absolute;
  left: 0; top: 0;
  z-index: 10;

  @media $small-mobile {
    width: 100%;
  }

  .menu {
    overflow: hidden;
    max-height: 0;
    padding: 0 50px 0 80px;
    transition: all .25s ease;
    opacity: 0;
    background: rgba($black, .8);

    @media $mobile {
      margin-left: -10px;
      margin-right: -10px;
    }

    li a, li span {
      display: block;
      line-height: 38px;
      text-transform: uppercase;
      font-family: $caption-font;
      font-size: 1.4rem;
      letter-spacing: 2px;
      transition: opacity .25s ease;
    }

    li a {
      &:hover {
        opacity: .65;
      }
    }

    li span {
      opacity: .6;
    }

    li li {
      padding-left: 15px;

      a {
        line-height: 25px;
      }
    }

    ul ul {
      margin-bottom: 5px;
    }
  }

  &.active .menu {
    opacity: 1;
    max-height: 600px;
    padding-top: 26px;
    padding-bottom: 12px;
  }

  li.menu-socials {
    white-space: nowrap;
    line-height: 38px;

    a {
      display: inline-block;
      vertical-align: middle;
      height: 33px;
      transition: all .25s ease;

      + a {
        margin-left: 25px;
      }

      &:hover {
        opacity: .65;
      }
    }

    for $social in $socials-list {
      .sm-{$social} {
        background: url('/i/sm-' + $social + '-mini.png') no-repeat 50%;
        width: width('i/sm-' + $social + '-mini.png');
      }
    }
  }
}

.header--menu-link {
  display: block;
  width: 68px; height: $header-height;
  position: absolute;
  left: 0; top: 0;
  cursor: pointer;

  @media $desktop {
    &:after {
      display: block;
      content: 'МЕНЮ';
      color: #606060;
      font-size: 1.8rem;
      line-height: 1.2;
      position: absolute;
      left: 100%; top: 50%;
      transform: translateY(-50%);
      margin-top: 2px;
      transition: all .25s ease;
    }

    &:hover:after {
      color: $white;
    }

    .active &:after {
      opacity: 0;
    }
  }

  @media $mobile {
    width: 50px; height: $mobile-header-height;
  }

  .bars {
    top: 50%;
    margin-top: -1px;

    &, &:before, &:after {
      display: block;
      width: 25px; height: 2px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background: $white;
      transition: height .25s ease;
    }

    &:before, &:after {
      content: '';
    }

    &:before {
      top: 8px;
      transition: all .25s ease;
    }

    &:after {
      bottom: 8px;
      transition: all .25s ease;
    }
  }

  .active & {
    .bars {
      height: 0;

      &:before {
        width: 30px;
        transform: rotate(-45deg) translate(-50%, -50%);
        transform-origin: top left;
        top: 0;
      }

      &:after {
        width: 30px;
        transform: rotate(45deg) translate(-50%, 50%);
        transform-origin: bottom left;
        bottom: 0;
      }
    }
  }
}

.header--right {
  position: absolute;
  right: 0; top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;

  @media only screen and (max-width: $wrapper-width + 20px) {
    right: 10px;
  }
}

.header--socials {
  white-space: nowrap;
  line-height: 38px;
  margin-right: 40px;

  @media $small-handheld {
    display: none;
  }

  a {
    display: inline-block;
    vertical-align: middle;
    height: 33px;
    transition: all .25s ease;
    opacity: .5;

    + a {
      margin-left: 25px;
    }

    &:hover {
      opacity: 1;
    }
  }

  for $social in $socials-list {
    .sm-{$social} {
      background: url('/i/sm-' + $social + '-mini.png') no-repeat 50%;
      width: width('i/sm-' + $social + '-mini.png');
    }
  }
}

.header--register-link {
  display: inline-block;
  vertical-align: middle;
  line-height: 51px;
  padding: 0 25px;
  background: $green;
  color: $white;
  font-size: 1.9rem;
  font-family: $caption-font;
  font-weight: bold;
  text-transform: uppercase;
/*   position: absolute;
  right: 0; top: 50%;
  transform: translateY(-50%); */

  &:hover {
    background: $white;
    color: $green;
  }

  @media only screen and (max-width: $wrapper-width + 20px) {
    right: 10px;
  }

  @media $mobile {
    font-size: 1.2rem;
    line-height: 25px;
  }

  @media $small-mobile {
    padding: 0 10px;
    letter-spacing: 0;
    font-size: 1.1rem;
  }
}

.header--timer {
  display: inline-block;
  vertical-align: middle;
  color: #949494;
  position: absolute;
  left: 25%; top: 50%;
  transform: translate(-50%, -50%);

  @media $landscape-mobile {
    display: none;
  }
}

.header-timer--label {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3px;

  @media $mobile {
    margin-bottom: 0;
  }
}

.header-timer--widget {
  display: flex;
  align-items: center;
}

.header-timer--section,
.header-timer--separator {
  position: relative;
  padding-bottom: 14px;

  @media $mobile {
    padding-bottom: 9px;
  }
}

.header-timer--separator {
  width: 14px;
  font-size: 1.9rem;
  font-weight: bold;
  text-align: center;
}

.header-timer--value {
  box-sizing: border-box;
  width: 30px;
  padding-left: 5px;
  font-family: $caption-font;
  font-size: 1.9rem;
  font-weight: bold;
  color: $white;
}

.header-timer--units {
  position: absolute;
  bottom: 0; left: 50%;
  transform: translateX(-50%);
  font-size: .8rem;
  font-weight: bold;
  text-transform: uppercase;
}

.header--stage {
  display: block;
  line-height: $header-height;
  color: $white;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.2rem;
  text-align: center;
  position: absolute;
  right: 0; top: 0;
  padding: 0 10px;

  @media $mobile {
    line-height: $mobile-header-height;
  }

  @media $small-mobile {
    font-size: 1rem;
  }

  span {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.2;
  }

  b {
    display: block;
    font-size: 1.9rem;
    font-weight: bold;
    font-family: $caption-font;

    @media $small-mobile {
      font-size: 1.3rem;
    }
  }
}


// Footer

.footer--first-line {
  background: $black;
  line-height: 105px;

  @media $mobile {
    background: #151515;
    padding-top: 35px;
  }

  .wrapper {
    display: flex;
    /* justify-content: space-between; */
    justify-content: center;
    align-items: center;
    padding: 15px 10px;

    @media $mobile {
      flex-wrap: wrap;
    }
  }
}

.footer--second-line {
  background: #0d0d0d;
  height: 54px;

  @media $mobile {
    display: none;
  }
}

.footer--third-line {
  background: $black;
  height: 78px;
}

.footer--logo-group {
  display: inline-block;
  vertical-align: middle;
  height: 100%;

  @media $mobile {
    order: 2;
  }
}

.footer--logo {
  display: inline-block;
  vertical-align: middle;
  width: width('i/marathon-logo.svg'); height: 100%;
  background: url(/i/marathon-logo.svg) no-repeat 50%;
  background-size: size('i/marathon-logo.svg');
  font-size: 0;

  @media $mobile {
    width: 50px; height: 36px;
    background-size: contain;
  }

  /* @media $small-mobile {
    height: height('i/marathon-logo.svg');
  } */
}

.footer--ripcurl-logo {
  display: inline-block;
  vertical-align: middle;
  width: width('i/ripcurl-logo.svg'); height: 100%;
  background: url(/i/ripcurl-logo.svg) no-repeat 50%;
  background-size: size('i/ripcurl-logo.svg');
  font-size: 0;
  position: relative;
  margin-left: 14px;

  &:before {
    content: '';
    display: block;
    width: width('i/logo-line.svg'); height: 100%;
    background: url(/i/logo-line.svg) no-repeat 50%;
    background-size: size('i/logo-line.svg');
    position: absolute;
    right: 100%; top: 0;
    margin-right: 3px;

    @media $mobile {
      height: 36px;
      background-size: contain;
    }
  }

  @media $mobile {
    width: 50px; height: 39px;
    background-size: contain;
  }

  /* @media $small-mobile {
    height: height('i/ripcurl-logo.svg');
  } */
}

.footer--socials,
.news--socials {
  a {
    display: inline-block;
    vertical-align: middle;
    width: 65px; height: 65px;
    transition: all .25s ease;

    @media $handheld {
      width: 32px; height: 32px;
    }

    + a {
      margin-left: 10px;
    }

    &:hover {
      opacity: .75;
    }
  }

  for $social in $socials-list {
    .sm-{$social} {
      background: url('/i/sm-' + $social + '-mini.png') no-repeat 50%;
    }
  }

  @media $mobile {
    order: 3;
  }
}

.footer--contact-link {
  @extend .btn, .white-bordered-btn;
  font-family: $caption-font;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 50px;
  padding: 0 20px;

  @media $mobile {
    order: 1;
    margin: auto;
    line-height: 35px;
  }
}

.footer--menu {
  @extend .menu, .justify-menu;

  @media $desktop {
    padding-left: 20px;
  }

  a {
    color: #868686;
    text-transform: uppercase;
    font-family: $caption-font;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 54px;
    transition: color .25s ease;

    &:hover {
      color: $white;
    }
  }
}

.footer--copyright {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.2;
  text-align: center;
  padding-top: 10px;

  a {
    color: #868686;
  }

  .icon {
    display: block;
    margin: 5px auto 10px;
    background: url(/i/shogo-logo.png) no-repeat 50%;
    width: width('i/shogo-logo.png');
    height: height('i/shogo-logo.png');
  }
}

.footer--counters {
  display: none;
}

.go-top-btn {
  width: 60px;
  padding-top: 135px;
  background: url(/i/summer17/lift.svg) no-repeat 50% 0 / contain;
  text-align: center;
  color: $green;
  font-size: 1.6rem;
  padding-bottom: 30px;
  position: fixed;
  right: 50px; bottom: 30px;
  z-index: 1000;
  opacity: 0;
  transform: scale(0);
  transform-origin: bottom center;
  transition: all .25s ease;
  cursor: pointer;

  &.is-visible {
    opacity: .75;
    transform: scale(1);

    &:hover {
      opacity: 1;
    }
  }

  &:before {
    content: 'Наверх';
  }

  &:after {
    content: '';
    display: block;
    width: 20px; height: 20px;
    border-top: 2px solid $green;
    border-right: 2px solid $green;
    transform: rotate(-45deg) translate(-50%, -50%);
    transform-origin: right center;
    position: absolute;
    bottom: 0; left: 50%;
    transition: all .25s ease;
  }
}

.tinkoff-float-link {
  display: none;
  width: 216px; height: 140px;
  position: fixed;
  left: 0; bottom: 5%;
  z-index: 100;
  background: url(/i/summer17/tinkoff-link.png) no-repeat 50%;

  &.is-visible {
    display: block;
  }

  &.is-collapsed {
    width: 59px; height: 182px;
    background-image: url(/i/summer17/tinkoff-link-mini.png);

    .close {
      display: none;
    }
  }

  .close {
    display: block;
    width: 40px; height: 40px;
    position: absolute;
    left: 0; top: -5px;
  }

  @media $small-handheld {
    display: none !important;
  }
}

.ripcurl-float-link {
  display: none;
  width: 422px; height: 253px;
  position: fixed;
  left: 0; bottom: 5%;
  z-index: 100;
  background: url(/i/summer17/ripcurl-link.png) no-repeat 50%;

  &.is-visible {
    display: block;
  }

  &.is-collapsed {
    width: 57px; height: 230px;
    background-image: url(/i/summer17/ripcurl-link-mini.png);

    .close {
      display: none;
    }
  }

  .close {
    display: block;
    width: 50px; height: 52px;
    position: absolute;
    left: 8px; top: 115px;
  }

  @media $small-handheld {
    display: none !important;
  }
}

.chronicles-float-link {
  display: none;
  width: 367px; height: 209px;
  position: fixed;
  left: 0; bottom: 5%;
  z-index: 100;
  background: url(/i/summer17/chronicles-link.png) no-repeat 50%;

  &.is-visible {
    display: block;
  }

  &.is-collapsed {
    width: 57px; height: 230px;
    background-image: url(/i/summer17/chronicles-link-mini.png);

    .close {
      display: none;
    }
  }

  .close {
    display: block;
    width: 50px; height: 52px;
    position: absolute;
    left: 0; top: 75px;
  }

  @media $small-handheld {
    display: none !important;
  }
}