
/*   -=========== Buttons ===========-   */

.btn {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: all .25s ease;
}

// use box-shadow instead of border to preserve button line-height
bordered-btn($border, $color) {
  box-shadow: inset 0 0 0 $border $color;
}

.black-bordered-btn {
  color: $black;
  bordered-btn(1px, $black);

  &:hover {
    color: $white;
    background: $black;
  }
}

.black-btn {
  background: $black;
  color: $black;

  &:hover {
    color: $black;
  }
}

.blue-btn {
  background: $blue;
  color: $white;

  &:hover {
    color: $blue;
    background: $white;
  }
}

.white-dotted-btn {
  color: $white;
  position: relative;

  &:before {
    content: '';
    display: block;
    box-sizing: border-box;
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
    border: 2px dotted $white;
    transition: border-color .25s ease;
  }

  .more-icon,
  .back-icon {
    display: inline-block;
    vertical-align: middle;
    height: 0;
    line-height: 0;
    position: relative;

    &:before, &:after {
      content: '';
      display: block;
      width: 1px; height: 18px;
      background: $white;
      position: absolute;
      left: 50%;
      transition: background-color .25s ease;
    }

    &:before {
      bottom: 0;
      transform-origin: bottom right;
    }

    &:after {
      top: 0;
      transform-origin: top right;
    }
  }

  .more-icon {
    margin-left: 20px;

    &:before {
      transform: rotate(-45deg) translateX(-50%);
    }

    &:after {
      transform: rotate(45deg) translateX(-50%);
    }
  }

  .back-icon {
    margin-right: 30px;

    &:before {
      transform: rotate(45deg) translateX(-50%);
    }

    &:after {
      transform: rotate(-45deg) translateX(-50%);
      top: -1px;
    }
  }

  &:hover {
    color: $blue;

    &:before {
      border-color: $blue;
    }

    .more-icon,
    .back-icon {
      &:before, &:after {
        background: $blue;
      }
    }
  }
}

.white-bordered-btn {
  color: $white;
  bordered-btn(1px, $white);
  position: relative;

  @media $not-mobile {
    &:hover {
      color: $black;
      background: $white;
    }
  }
}

.blue-dotted-btn {
  color: $blue;
  position: relative;

  &:before {
    content: '';
    display: block;
    box-sizing: border-box;
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
    border: 2px dotted $blue;
    transition: border-color .25s ease;
  }

  &:hover {
    color: $white;
    background: $blue;

    &:before {
      border-color: $white;
    }
  }
}

.black-dotted-btn {
  color: $black;
  position: relative;

  &:before {
    content: '';
    display: block;
    box-sizing: border-box;
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
    border: 2px dotted $black;
    transition: border-color .25s ease;
  }

  &:hover {
    color: $black;
    background: $white;

    &:before {
      /* border-color: $blue; */
    }
  }
}


// Sizes

.wide-btn {
  width: 100%;
}

.h50btn {
  line-height: 50px;
}

.h62btn {
  line-height: 62px;
}

.h65btn {
  line-height: 65px;
}

.p20btn {
  padding: 0 20px;
}

.p30btn {
  padding: 0 30px;
}

.p45btn {
  padding: 0 45px;
}