
/*   -=========== Rating ===========-   */

.rating-section {
  background: $blue;
  padding: 40px 0 60px;
  position: relative;

  @media $small-mobile {
    padding-bottom: 0;

    .wrapper {
      padding: 0;
    }
  }

  .rating-spots-1 {
    width: 16%;
    padding-bottom: 37%;
    position: absolute;
    left: 0; top: 14%;
    background: url(/i/rating-spots-1.png) no-repeat 0 50% / contain;

    @media $handheld {
      display: none;
    }
  }

  .rating-spots-2 {
    width: 16%;
    padding-bottom: 20%;
    position: absolute;
    top: 37%; right: 0;
    background: url(/i/rating-spots-2.png) no-repeat 100% 0 / contain;

    @media $handheld {
      display: none;
    }
  }
}

.rating-section--caption {
  text-transform: uppercase;
  color: $black;
  font-size: 3.6rem;
  line-height: 1;
  font-family: $caption-font;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;

  @media $small-mobile {
    font-size: 2.6rem;
  }
}

.rating-tabs {
  position: relative;
  z-index: 10;

  > .tabs-nav {
    border-bottom: 2px dotted #015c80;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;

    li {
      list-style: none;

      &.disabled {
        opacity: .5;

        a {
          cursor: default;
        }
      }
    }

    a {
      display: block;
      color: $white;
      font-family: $caption-font;
      font-size: 2.5rem;
      font-weight: bold;
      text-decoration: none;
      padding: 0 18px;
      min-width: 130px;
      text-align: center;
      border: 2px dotted transparent;
      line-height: 50px;
      margin-bottom: -2px;

      @media $small-notebook {
        min-width: 100px;
      }

      @media $handheld {
        min-width: auto;
      }

      @media $small-mobile {
        font-size: 1.2rem;
        padding: 0 10px;
      }
    }

    li.active a {
      color: #1e5075;
      border-color: #015c80;
      border-bottom: 2px solid $blue;
    }
  }
}

.rating-table-tabs {
  > .tabs-nav {
    display: flex;
    justify-content: space-between;

    li {
      list-style: none;
      flex: 1;
      color: #1e5075;
      font-family: $caption-font;
      font-size: 2.5rem;
      line-height: 80px;
      text-align: center;

      @media $small-mobile {
        font-size: 1rem;
        font-weight: normal;
        text-transform: none;
      }

      &:first-child {
        min-width: 400px;

        @media $handheld {
          min-width: 0;
        }

        @media $small-mobile {
          display: none;
        }
      }

      + li {
        margin-left: 15px;

        @media $small-mobile {
          margin-left: 0;
        }
      }
    }

    a {
      display: block;
      height: 80px;
      line-height: 82px;
      color: $white;
      text-transform: uppercase;
      font-family: $caption-font;
      font-size: 1.6rem;
      font-weight: bold;
      text-decoration: none;
      border: 2px dotted white;
      border-bottom: none;
      text-align: center;
      padding: 0 10px;

      @media $small-mobile {
        font-size: 1rem;
        font-weight: normal;
        text-transform: none;
        font-family: $text-font;
        padding: 0 5px;
      }

      span {
        display: inline-block;
        vertical-align: top;
        padding-top: 34px;
        line-height: 1.2;
        margin-top: 5px;
      }
    }

    li.active a {
      background: #1e5075;
      color: #48b2ff;
      border-color: transparent;
    }

    .icon-chile {
      background: url(/i/icon-chile.png) no-repeat 50% 0;
    }

    .icon-portugal {
      background: url(/i/icon-portugal.png) no-repeat 50% 3px;
    }

    .icon-cube {
      background: url(/i/icon-cube.png) no-repeat 50% 5px;

      @media $small-mobile {
        background-size: 90% auto;
      }
    }

    .icon-sony {
      background: url(/i/icon-sony.png) no-repeat 50% 9px;

      @media $small-mobile {
        background-size: 90% auto;
      }
    }
  }

  @media $small-mobile {
    .tabs-panel {
      max-width: 320px;
      overflow: auto;
    }
  }
}

.rating-table {
  background: #1e5075;

  td {
    font-family: $caption-font;
    font-size: 2.9rem;
    border-bottom: 1px solid $blue;
    padding: 12px;

    @media $small-mobile {
      font-size: 1.2rem;
      padding: 12px 5px;
    }
  }

  th {
    font-family: $caption-font;
    font-size: 1.1rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #48b2ff;
    text-align: center;
    padding: 24px 12px 12px;

    @media $handheld {
      font-size: 1.2rem;
    }

    @media $small-mobile {
      font-size: .9rem;
      font-weight: normal;
      padding: 15px 2px 0;
      font-family: $text-font;
      text-transform: none;

      div {
        height: 30px;
      }
    }

    &:last-child {
      padding-top: 58px;

      @media $small-mobile {
        padding-top: 40px;
      }
    }
  }

  tr td:first-child {
    font-weight: bold;
    text-transform: uppercase;
    color: $blue;
    padding: 12px 0 12px 18px;
    width: 1%;
    line-height: 1;

    @media $mobile {
      font-size: 1.6rem;
    }

    @media $small-mobile {
      font-size: 1.2rem;
      padding-left: 5px;
    }

    + td {
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1;
      /* white-space: nowrap; */

      @media $mobile {
        font-size: 1.6rem;
      }

      @media $small-mobile {
        font-size: 1.2rem;
      }

      a {
        text-decoration: none;
        display: inline-block;
        vertical-align: middle;
        border-bottom: 2px solid rgba($white, .75);

        @media $small-mobile {
          text-decoration: underline;
          border-bottom: none;
        }
      }

      img {
        margin-left: 10px;

        @media $mobile {
          margin-top: -10px;
          margin-bottom: -7px;
        }

        @media $small-mobile {
          display: none;
        }
      }

      ~ td {
        text-align: center;
        white-space: nowrap;

        @media $mobile {
          font-size: 1.4rem;
        }

        @media $small-mobile {
          font-size: 1.2rem;
        }
      }
    }
  }

  .rating-hint {
    display: inline-block;
    width: 34px; height: 31px;
    background: url(/i/info-icon-mini.png) no-repeat 50% / contain;
  }
}

.rating-hint-popup {
  max-width: 700px;
  padding: 25px 55px 30px;
}