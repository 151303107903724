
/*   -=========== Homepage ===========-   */

.intro-video-slide {
  position: relative;
  z-index: 20;

  .video-bg {
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
    overflow: hidden;
    background: url(/i/video-cover.jpg) no-repeat 50% / cover;

    video {
      min-width: 100%; min-height: 100%;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);

      @media $small-mobile {
        display: none;
      }
    }
  }

  .wrapper {
    height: 100%;
    position: relative;
  }
}

.intro-video-slide--skip,
.intro-video-slide--play {
  @extend .btn, .white-dotted-btn, .h50btn, .p20btn, .caption-font, .s19, .uppercase, .bb;
  position: absolute;
  bottom: 11%;

  @media $mobile {
    font-size: 1.5rem;
  }
}

.intro-video-slide--skip {
  right: 0;

  @media only screen and (max-width: $wrapper-width + 20px) {
    right: 10px;
  }

  @media $small-mobile {
    display: none;
  }
}

.intro-video-slide--play {
  left: 0;

  @media only screen and (max-width: $wrapper-width + 20px) {
    left: 10px;
  }

  @media $small-mobile {
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
}

.intro-section {
  background: $white url(/i/summer/bg-1.jpg) no-repeat 50% $header-height;
  position: relative;
  z-index: 2;
  /* will-change: background-position; */

  min-height: 636px;

  @media $small-mobile {
    min-height: 710px;
  }

  @media $mobile {
    background-position: 50% $mobile-header-height;
  }

  /* @media $mobile {
    background-position: 50%;
  }

  @media $small-mobile {
    background-size: cover;
  } */

  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 36%;

    @media $small-mobile {
      padding-bottom: 100%;
    }
  }

  &:after {
    content: '';
    display: block;
    width: 100%; height: $header-height;
    position: absolute;
    left: 0; top: 0;
    background: $black;

    @media $mobile {
      height: $mobile-header-height;
    }
  }

  /* &:after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 17%;
    background: url(/i/intro-transition.png) no-repeat 50% / 100% auto;
    position: absolute;
    left: 0; bottom: 0;
    transform: translateY(50%);
  } */

  h1 {
    font-size: 0;
  }

  .inner {
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;
    overflow: hidden;
    z-index: 10;
  }

  .intro-board {
    width: 36%;
    padding-bottom: 19%;
    background: url(/i/intro-board.png) no-repeat 0 50% / contain;
    position: absolute;
    right: -@width; top: 32%;
    opacity: 0;
    transition: all .75s .75s ease;
    will-change: opacity, right;

    @media $small-mobile {
      width: 43%;
      padding-bottom: 23%;
    }
  }

  .intro-logo {
    width: 29%;
    padding-bottom: 18%;
    background: url(/i/intro-logo.png) no-repeat 50% / contain;
    position: absolute;
    left: 31%; top: 27%;

    @media $small-mobile {
      width: 40%;
      padding-bottom: 25%;
      left: 15%; top: 34%;
    }
  }

  .intro-logo--link {
    display: block;
    width: 38%; height: 42%;
    position: absolute;
    right: 0; top: 34%;
  }

  .wrapper {
    position: relative;
    height: 100%;
  }

  .intro-caption {
    width: 50%;
    padding-bottom: 43%;
    position: absolute;
    left: 0; top: $header-height;
    background: url(/i/summer/intro-caption.png) no-repeat 0 / contain;
    opacity: 0;
    transform: rotate(-30deg);
    /* transform-origin: left bottom; */
    transition: all .5s .75s ease;
    will-change: transform, opacity, top;
    font-size: 0;

    @media $small-mobile {
      width: 100%;
      padding-bottom: 75%;
      /* padding-bottom: 25%; */
      background-position: 50%;
    }
  }

  .intro--text {
    position: absolute;
    top: 62%; right: 0;
    width: 55%;
    font-weight: bold;
    color: $black;
    text-align: center;

    @media $notebook {
      width: 48%;
      top: 65%;
    }

    @media $handheld {
      width: 47%;
      top: 65%;
    }

    @media $mobile {
      width: 58%;
      right: 10px;
    }

    @media $small-mobile {
      width: calc(100% - 10px);
      top: 63%;
    }
  }

  .intro-text--inner {
    display: inline-block;
    text-align: right;
  }

  .intro--line-1 {
    font-size: 2.6rem;
    line-height: 1.5;

    @media $handheld {
      font-size: 2.3rem;
    }

    @media $mobile {
      font-size: 2.1rem;
    }

    @media $small-mobile {
      font-size: 1.7rem;
      padding-left: 10px;
    }
  }

  .intro--line-2 {
    font-size: 4.2rem;
    line-height: 1.1;

    @media $handheld {
      font-size: 3.8rem;
    }

    @media $mobile {
      font-size: 3.5rem;
    }

    @media $small-mobile {
      font-size: 2.7rem;
    }
  }

  .intro--line-3 {
    margin-top: 20px;
  }

  .intro--register-btn {
    display: inline-block;
    vertical-align: middle;
    line-height: 51px;
    padding: 0 25px;
    background: $green;
    color: $white;
    font-size: 1.8rem;
    font-family: $caption-font;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-decoration: none;

    &:hover {
      background: lighten($green, 10);
      color: $white;
    }

    @media $mobile {
      font-size: 1.2rem;
      line-height: 38px;
    }

    @media $small-mobile {
      padding: 0 10px;
      letter-spacing: 0;
      font-size: 1.1rem;
    }
  }

  .intro-sponsors {
    position: absolute;
    left: 50%; top: 69%;
    transform: translateX(-50%);
    color: $black;

    @media only screen and (max-width: 1300px) {
      top: auto;
      bottom: 2%;
    }

    @media $small-mobile {
      width: 80%;
    }
  }

  @media $small-mobile {
    .intro-sponsors--caption {
      font-size: 1.3rem;
      white-space: nowrap;
    }
  }

  .intro-sponsors--line {
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      margin-bottom: 40px;

      @media $small-mobile {
        margin-bottom: 10px;
      }

      * + * {
        margin-left: 50px;

        @media $small-mobile {
          margin-left: 10px;
        }
      }

      > a, > img {
        max-width: 30%;
      }
    }

    &:last-child {
      * + * {
        margin-left: 70px;

        @media $small-mobile {
          margin-left: 10px;
        }
      }

      @media $small-mobile {
        > a, > img {
          max-width: 22%;
        }
      }
    }

    > a, > img {
      max-width: 16%;
    }
  }

  .intro-spots {
    width: 48%;
    padding-bottom: 23%;
    background: url(/i/intro-spots.png) no-repeat 0 50% / contain;
    position: absolute;
    left: 0; bottom: 0;
    z-index: 2;
    transition: opacity .5s ease;
    opacity: 0;

    @media $small-mobile {
      bottom: 30%;
    }
  }

  &.inview {
    .intro-caption {
      /* top: $header-height; */
      opacity: 1;
      transform: rotate(0deg);
    }

    .intro-board {
      right: 0%;
      opacity: 1;
    }

    .intro-spots {
      opacity: 1;
    }
  }

  @media $mobile {
    .intro-caption {
      top: $mobile-header-height;
      opacity: 1;
      transform: rotate(0deg);
    }

    .intro-board {
      right: 0%;
      opacity: 1;
    }

    .intro-spots {
      opacity: 1;
    }
  }
}

.prize-section {
  background: url(/i/bg-2.jpg) no-repeat 50% / cover;
  position: relative;

  @media $small-mobile {
    margin-bottom: 100px;

    &:after {
      content: '';
      display: block;
      width: 100%; height: 100px;
      background: $blue;
      position: absolute;
      left: 0; top: 100%;
    }
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 145%;
  }

  .inner {
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;

    @media $not-small-mobile {
      overflow: hidden;
    }

    .wrapper {
      height: 100%;
      position: relative;
    }
  }

  .prize-caption-1 {
    width: 57%;
    padding-bottom: 20%;
    position: absolute;
    left: 45%; top: 5%;
    transform: translateX(-50%);
    background: url(/i/prize-caption-1.png) no-repeat 50% / contain;
    font-size: 0

    .prize-bg {
      width: 100%; height: 100%;
      position: absolute;
      left: 0; top: 0;
      clip-path: url(#prize-clip-path-1);
      overflow: hidden;

      &:before {
        content: '';
        display: block;
        width: 100%; height: 100%;
        position: absolute;
        left: 50%; top: 50%;
        background: url(/i/prize-caption-1-bg.jpg) no-repeat 50% / 100% auto;
        animation: prize-1-caption-animation infinite 12s linear;
        will-change: transform;
      }
    }

    @keyframes prize-1-caption-animation {
      /* 0%   { transform: translate(-50%, -50%) rotate(0deg); }
      100% { transform: translate(-50%, -50%) rotate(360deg); } */
      0%   { transform: translate(-50%, -50%) scale(1) rotate(0deg); }
      50%  { transform: translate(-50%, -50%) scale(1.1) rotate(5deg); }
      100% { transform: translate(-50%, -50%) scale(1) rotate(0deg); }
    }

    @media $small-mobile {
      width: 75%;
      padding-bottom: 28%;
      left: 47%;
    }
  }

  .prize-caption-2 {
    width: 57%;
    padding-bottom: 20%;
    position: absolute;
    left: 45%; top: 47%;
    transform: translateX(-50%);
    background: url(/i/prize-caption-2.png) no-repeat 50% / contain;
    font-size: 0;

    .prize-bg {
      width: 100%; height: 100%;
      position: absolute;
      left: 0; top: 0;
      clip-path: url(#prize-clip-path-2);
      overflow: hidden;

      &:before {
        content: '';
        display: block;
        width: 100%; height: 100%;
        position: absolute;
        left: 50%; top: 50%;
        background: url(/i/prize-caption-2-bg.jpg) no-repeat 50% / 100% auto;
        animation: prize-1-caption-animation infinite 16s linear;
        will-change: transform;
      }
    }

    @keyframes prize-1-caption-animation {
      /* 0%   { transform: translate(-50%, -50%) rotate(0deg); }
      100% { transform: translate(-50%, -50%) rotate(360deg); } */
      0%   { transform: translate(-50%, -50%) scale(1) rotate(0deg); }
      50%  { transform: translate(-50%, -50%) scale(1.2) rotate(-7deg); }
      100% { transform: translate(-50%, -50%) scale(1) rotate(0deg); }
    }

    @media $small-mobile {
      width: 75%;
      padding-bottom: 28%;
      left: 47%;
    }
  }

  .prize-caption-3 {
    width: 57%;
    padding-bottom: 20%;
    position: absolute;
    left: 45%; bottom: 11%;
    transform: translateX(-50%);
    background: url(/i/prize-caption-3.png) no-repeat 50% / contain;
    font-size: 0;

    @media $small-mobile {
      width: 75%;
      padding-bottom: 28%;
      left: 47%;
    }
  }

  .prize-hint {
    position: absolute;
    left: 0; bottom: 30px;
    font-size: 1.4rem;
    color: $black;

    @media only screen and (max-width: $wrapper-width + 20px) {
      left: 10px;
      color: $white;
    }

    @media $small-mobile {
      font-size: 1.2rem;
    }
  }

  .scrollmagic-pin-spacer {
    width: 100% !important;
  }

  .prize-spots-1 {
    width: 27%;
    padding-bottom: 20%;
    position: absolute;
    top: 0; right: 0;
    background: url(/i/prize-spots-1.png) no-repeat 100% 0 / contain;
  }

  .prize-spots-2 {
    width: 30%;
    padding-bottom: 37%;
    position: absolute;
    left: 0; top: 14%;
    background: url(/i/prize-spots-2.png) no-repeat 0 50% / contain;
  }
}

.prize-section--full-link {
  @extend .btn, .blue-btn, .white-dotted-btn, .h62btn, .p45btn, .s26, .bb, .uppercase, .caption-font;
  position: absolute;
  left: 50%; bottom: 30px;
  z-index: 10;
  transform: translateX(-50%);

  @media $mobile {
    @media $not-small-mobile {
      right: 20px; left: auto;
      transform: none;
    }
  }

  @media $small-mobile {
    white-space: nowrap;
    bottom: -71px;
  }
}

.stages-section {
  background: #3c8fcb url(/i/bg-3.jpg) no-repeat 50% 100% / 100% auto;
  position: relative;

  @media $mobile {
    background-size: cover;
  }

  @media $small-mobile {
    &:first-child {
      padding-top: 40px;
    }
  }

  /* @media $small-mobile {
    padding-top: 0;
    background: #3c8fcb;
  } */

  @media $landscape-mobile {
    padding-top: 0;
    background: #3c8fcb;
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 69%;

    @media $mobile {
      padding-bottom: calc(72% + 110px);
    }

    /* @media $small-mobile {
      display: none;
    } */

    @media $landscape-mobile {
      display: none;
    }
  }

  .inner {
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    /* @media $small-mobile {
      position: relative;
      top: 0;
      height: auto;
      display: block;
    } */

    @media $landscape-mobile {
      position: relative;
      top: 0;
      height: auto;
      display: block;
    }
  }

  .stage-content {
    width: 100%;
    text-align: center;
    color: $black;
    margin-top: 9%;
    position: relative;
    z-index: 10;

    @media $tablet {
      margin-top: 4%;
    }

    @media $mobile {
      margin-bottom: 110px;
      margin-top: 14%;
    }

    /* @media $small-mobile {
      margin-top: 20px;
      margin-bottom: 20px;
    } */

    @media $landscape-mobile {
      margin-top: 40px;
      margin-bottom: 20px;
    }

    @media $small-mobile {
      margin-top: 20px;
    }
  }

  .stage-content--icon {
    height: 123px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    margin-bottom: 15px;
  }

  .stage-1 {
    .stage-content--icon {
      background-image: url(/i/stage-1-active.svg);
    }
  }

  .stage-2 {
    &.stage-content {
      padding-bottom: 120px;
    }

    .stage-content--icon {
      background-image: url(/i/stage-2-active.svg);
    }

    .stage-content--text {
      width: 100%;
      padding: 0;
      background: none;
    }

    .item--text {
      padding: 4% 0 6%;
      background: url(/i/stage-text-bg.png) no-repeat 50% / contain;

      @media $handheld {
        padding: 6% 0 15%;
        background-size: cover;
      }

      @media $mobile {
        padding: 9% 0 20%;
      }

      @media $small-mobile {
        padding: 80px 10px 150px;
      }
    }

    .stage--items {
      display: flex;
      justify-content: center;
      margin-top: -5%;

      @media $small-mobile {
        flex-direction: column;
        align-items: center;
      }

      .item {
        width: 170px;
        position: relative;

        &:before {
          content: '';
          display: block;
          padding-bottom: 100%;
        }

        @media $not-small-mobile {
          + .item {
            margin-left: 30px;
          }
        }
      }

      .item-1 {
        background: url(/i/stage-2-icon-1.png) no-repeat 50% / contain;
      }

      .item-2 {
        background: url(/i/stage-2-icon-2.png) no-repeat 50% / contain;
      }

      .item-3 {
        background: url(/i/stage-2-icon-3.png) no-repeat 50% / contain;
      }

      .info-icon {
        width: 66px; height: 60px;
        background: url(/i/info-icon.png) no-repeat 50%;
        position: absolute;
        right: -20px; top: -20px;
        cursor: pointer;

        @media $tablet {
          right: -10px; top; -10px;
          width: 50px; height: 50px;
          background-size: contain;
        }

        @media $small-mobile {
          right: -5px; top; -5px;
          width: 40px; height: 40px;
          background-size: contain;
        }
      }
    }

    .video-link {
      @extend .btn, .blue-btn, .h62btn, .uppercase, .s26, .bb, .caption-font;
      letter-spacing: 3px;
      padding: 0 88px 0 38px;
      position: relative;

      @media $small-mobile {
        font-size: 1.3rem;
        letter-spacing: 1px;
      }

      &:after {
        content: '';
        display: block;
        width: 88px; height: 100%;
        background: url(/i/video-play-white.png) no-repeat 50%;
        position: absolute;
        right: 0; top: 0;
      }

      &:hover:after {
        background-image: url(/i/video-play-blue.png);
      }
    }
  }

  .stage-3 {
    &.stage-content {
      padding-bottom: 120px;
      margin-top: 4%;
      pointer-events: none;

      @media $small-mobile {
        padding-bottom: 200px;
      }
    }

    .stage-content--icon {
      background-image: url(/i/stage-3-active.svg);
    }

    .stage-content--text {
      padding: 0;
      background: none;
      position: relative;
      pointer-events: auto;

      @media $not-mobile {
        width: 50%;
        transform: translateX(-30%);
      }

      @media $mobile {
        width: 100%;
      }
    }

    .item--text {
      padding: 6% 0 16%;
      background: url(/i/stage-text-bg-tight.png) no-repeat 50% / contain;

      span {
        @media $notebook {
          background: #0090ce;
        }

        @media $landscape-tablet {
          background: #0090ce;
        }
      }

      @media $handheld {
        padding: 6% 0 15%;
        /* background-size: cover; */
      }

      @media $mobile {
        box-sizing: border-box;
        width: 100%;
        padding: 8% 22% 18%;
        white-space: nowrap;
      }

      @media $small-mobile {
        padding: 20px 10px;
        white-space: normal;
        background: #0090ce;
        margin: 30px 0 70px;

        br {
          display: none;
        }

        a {
          white-space: nowrap;
        }
      }
    }

    .stage--items {
      display: flex;
      justify-content: center;
      margin-top: -13%;

      @media $small-mobile {
        flex-direction: column;
        align-items: center;
      }

      .item {
        width: 170px;
        position: relative;

        &:before {
          content: '';
          display: block;
          padding-bottom: 100%;
        }

        @media $not-small-mobile {
          + .item {
            margin-left: 30px;
          }
        }
      }

      .item-1 {
        background: url(/i/stage-2-icon-1.png) no-repeat 50% / contain;
      }

      .info-icon {
        width: 66px; height: 60px;
        background: url(/i/info-icon.png) no-repeat 50%;
        position: absolute;
        right: -20px; top: -20px;
        cursor: pointer;

        @media $tablet {
          right: -10px; top; -10px;
          width: 50px; height: 50px;
          background-size: contain;
        }

        @media $small-mobile {
          right: -5px; top; -5px;
          width: 40px; height: 40px;
          background-size: contain;
        }
      }
    }

    .video-link-wrapper {
      @media $not-mobile {
        margin-right: -50%;
      }
    }

    .video-link {
      @extend .btn, .blue-btn, .h62btn, .uppercase, .s26, .bb, .caption-font;
      letter-spacing: 3px;
      padding: 0 88px 0 38px;
      position: relative;

      @media $small-mobile {
        font-size: 1.3rem;
        letter-spacing: 1px;
      }

      &:after {
        content: '';
        display: block;
        width: 88px; height: 100%;
        background: url(/i/video-play-white.png) no-repeat 50%;
        position: absolute;
        right: 0; top: 0;
      }

      &:hover:after {
        background-image: url(/i/video-play-blue.png);
      }

      @media $mobile {
        @media $not-small-mobile {
          margin-left: -35%;
        }
      }
    }
  }

  .stage-4 {
    &.stage-content {
      padding-bottom: 120px;

      @media $small-mobile {
        padding-bottom: 30px;
      }
    }

    .stage-content--icon {
      background-image: url(/i/stage-4-active.svg);
    }

    .stage-content--text {
      width: 100%;
      padding: 0;
      background: none;
    }

    .item--text {
      padding: 4% 0 6%;
      background: url(/i/stage-text-bg.png) no-repeat 50% / contain;

      @media $handheld {
        padding: 6% 0 15%;
        background-size: cover;
      }

      @media $mobile {
        padding: 9% 0 20%;
      }

      @media $small-mobile {
        padding: 80px 10px 150px;
      }
    }

    .stage--items {
      display: flex;
      justify-content: center;
      margin-top: -5%;

      @media $small-mobile {
        flex-direction: column;
        align-items: center;
      }

      .item {
        width: 170px;
        position: relative;

        &:before {
          content: '';
          display: block;
          padding-bottom: 100%;
        }

        @media $not-small-mobile {
          + .item {
            margin-left: 30px;
          }
        }
      }

      .item-1 {
        background: url(/i/stage-2-icon-1.png) no-repeat 50% / contain;
      }

      .item-2 {
        background: url(/i/stage-2-icon-1.png) no-repeat 50% / contain;
      }

      .item-3 {
        background: url(/i/stage-2-icon-2.png) no-repeat 50% / contain;
      }

      .info-icon {
        width: 66px; height: 60px;
        background: url(/i/info-icon.png) no-repeat 50%;
        position: absolute;
        right: -20px; top: -20px;
        cursor: pointer;

        @media $tablet {
          right: -10px; top; -10px;
          width: 50px; height: 50px;
          background-size: contain;
        }

        @media $small-mobile {
          right: -5px; top; -5px;
          width: 40px; height: 40px;
          background-size: contain;
        }
      }
    }

    .video-link {
      @extend .btn, .blue-btn, .h62btn, .uppercase, .s26, .bb, .caption-font;
      letter-spacing: 3px;
      padding: 0 88px 0 38px;
      position: relative;

      @media $small-mobile {
        font-size: 1.3rem;
        letter-spacing: 1px;
      }

      &:after {
        content: '';
        display: block;
        width: 88px; height: 100%;
        background: url(/i/video-play-white.png) no-repeat 50%;
        position: absolute;
        right: 0; top: 0;
      }

      &:hover:after {
        background-image: url(/i/video-play-blue.png);
      }
    }
  }

  &.stage-4 {
    .cube-caption-1 {
      display: block;
      width: 45%;
      padding-bottom: 17%;
      position: absolute;
      left: 0; top: 10%;
      z-index: 100;
      background: url(/i/stage-4-caption-1.png) no-repeat 0 50% / contain;

      @media $small-mobile {
        display: none;
      }

      .btn {
        @extend .white-dotted-btn;
        line-height: 30px;
        padding: 0 10px;
        position: absolute;
        right: 20px; top: 40%;

        @media only screen and (max-width: 1750px) {
          left: 50%; right: auto; top: 80%;

          &:hover {
            background: $white;
          }
        }

        &:after {
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 5px; height: 9px;
          background: url(/i/arrow-right-mini.png) no-repeat 50%;
          margin-left: 2px;
        }

        &:hover:after {
          background-image: url(/i/arrow-right-mini-blue.png);
        }

        span {
          display: inline-block;
          line-height: 1.1;
          border-bottom: 1px solid rgba($white, .5);
          transition: border-bottom-color .25s ease;
        }

        &:hover {
          span {
            border-bottom-color: rgba($blue, .5);
          }
        }
      }
    }

    .cube-caption-2 {
      display: block;
      width: 45%;
      padding-bottom: 17%;
      position: absolute;
      right: 0; top: 10%;
      z-index: 100;
      background: url(/i/stage-4-caption-2.png) no-repeat 0 0 / 65% auto;

      @media $small-mobile {
        display: none;
      }

      .btn {
        @extend .white-dotted-btn;
        line-height: 30px;
        padding: 0 10px;
        position: absolute;
        left: 10px; top: 40%;

        @media $mobile {
          top: 80%;
        }

        &:after {
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 9px; height: 5px;
          background: url(/i/arrow-down-mini.png) no-repeat 50%;
          margin-left: 2px;
        }

        &:hover:after {
          background-image: url(/i/arrow-down-mini-blue.png);
        }

        span {
          display: inline-block;
          line-height: 1.1;
          border-bottom: 1px solid rgba($white, .5);
          transition: border-bottom-color .25s ease;
        }

        &:hover {
          span {
            border-bottom-color: rgba($blue, .5);
          }
        }
      }
    }
  }

  .stage-5 {
    &.stage-content {
      padding-bottom: 120px;
      margin-top: 4%;

      @media $small-mobile {
        padding-bottom: 30px;
      }
    }

    .stage-content--icon {
      background-image: url(/i/stage-5-active.svg);
    }

    .stage-content--text {
      width: 100%;
      padding: 0;
      background: none;
    }

    .item--text {
      padding: 3% 0 6%;
      background: url(/i/stage-text-bg-short.png) no-repeat 60% 50% / contain;

      @media $handheld {
        padding: 6% 0 15%;
        background-size: cover;
      }

      @media $mobile {
        padding: 9% 0 20%;
      }

      @media $small-mobile {
        padding: 80px 10px 150px;
      }
    }

    .stage--items {
      display: flex;
      justify-content: center;
      margin-top: -5%;

      @media $small-mobile {
        flex-direction: column;
        align-items: center;
      }

      .item {
        width: 170px;
        position: relative;

        &:before {
          content: '';
          display: block;
          padding-bottom: 100%;
        }

        @media $not-small-mobile {
          + .item {
            margin-left: 30px;
          }
        }
      }

      .item-1 {
        background: url(/i/stage-2-icon-1.png) no-repeat 50% / contain;
      }

      .info-icon {
        width: 66px; height: 60px;
        background: url(/i/info-icon.png) no-repeat 50%;
        position: absolute;
        right: -20px; top: -20px;
        cursor: pointer;

        @media $tablet {
          right: -10px; top; -10px;
          width: 50px; height: 50px;
          background-size: contain;
        }

        @media $small-mobile {
          right: -5px; top; -5px;
          width: 40px; height: 40px;
          background-size: contain;
        }
      }
    }

    .video-link {
      @extend .btn, .blue-btn, .h62btn, .uppercase, .s26, .bb, .caption-font;
      letter-spacing: 3px;
      padding: 0 88px 0 38px;
      position: relative;

      @media $small-mobile {
        font-size: 1.3rem;
        letter-spacing: 1px;
      }

      &:after {
        content: '';
        display: block;
        width: 88px; height: 100%;
        background: url(/i/video-play-white.png) no-repeat 50%;
        position: absolute;
        right: 0; top: 0;
      }

      &:hover:after {
        background-image: url(/i/video-play-blue.png);
      }
    }
  }

  &.stage-5 {
    .ripcurl-caption-1 {
      display: block;
      width: 45%;
      padding-bottom: 18%;
      position: absolute;
      left: 0; top: 5%;
      z-index: 100;
      background: url(/i/stage-5-caption-1.png) no-repeat 0 50% / contain;

      @media $small-mobile {
        display: none;
      }

      .btn {
        @extend .white-dotted-btn;
        line-height: 30px;
        padding: 0 10px;
        position: absolute;
        right: 30px; top: 44%;
        white-space: nowrap;

        @media only screen and (max-width: 1750px) {
          left: 50%; right: auto; top: 80%;
          background: $blue;

          &:hover {
            background: $white;
          }
        }

        @media $mobile {
          left: 40%;
        }

        &:after {
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 5px; height: 9px;
          background: url(/i/arrow-right-mini.png) no-repeat 50%;
          margin-left: 2px;
        }

        &:hover:after {
          background-image: url(/i/arrow-right-mini-blue.png);
        }

        span {
          display: inline-block;
          line-height: 1.1;
          border-bottom: 1px solid rgba($white, .5);
          transition: border-bottom-color .25s ease;
        }

        &:hover {
          span {
            border-bottom-color: rgba($blue, .5);
          }
        }
      }
    }

    .ripcurl-caption-2 {
      display: block;
      width: 45%;
      padding-bottom: 16%;
      position: absolute;
      right: 0; top: 0;
      z-index: 100;
      background: url(/i/stage-5-caption-2.png) no-repeat 0 0 / contain;

      @media $handheld {
        top: 3%;
        right: -1%;
      }

      @media $small-mobile {
        display: none;
      }

      .btn {
        @extend .white-dotted-btn;
        line-height: 30px;
        padding: 0 10px;
        position: absolute;
        left: 52%; top: 60%;

        @media $mobile {
          top: 80%;
          background: $blue;
        }

        &:after {
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 5px; height: 9px;
          background: url(/i/arrow-right-mini.png) no-repeat 50%;
          margin-left: 2px;
        }

        &:hover:after {
          background-image: url(/i/arrow-right-mini-blue.png);
        }

        span {
          display: inline-block;
          line-height: 1.1;
          border-bottom: 1px solid rgba($white, .5);
          transition: border-bottom-color .25s ease;
        }

        &:hover {
          span {
            border-bottom-color: rgba($blue, .5);
          }
        }
      }
    }
  }

  @media $mobile {
    .stages-line {
      z-index: 20;
    }
  }

  .cam-widget {
    background: url(/i/cam.png) no-repeat 50% / contain;
    width: 50%;
    position: absolute;
    right: 0%; bottom: 0%;
    z-index: 0;

    @media $notebook {
      width: 70%;
      right: -15%;
    }

    @media $handheld {
      width: 80%;
      right: -22%;
    }

    @media $mobile {
      z-index: 15;
    }

    @media $small-mobile {
      width: 120%;
      bottom: auto; top: 48%; right: -16%;
    }

    &:before {
      content: '';
      display: block;
      padding-bottom: 91%;
    }

    .cam-works-link {
      display: block;
      width: 28%;
      position: absolute;
      left: 31.5%; top: 28.6%;
      transform: translateY(-35%);
      background: rgba($black, .79);
      transition: opacity .35s ease;

      @media $desktop {
        opacity: 0;

        &:hover {
          opacity: 1;
        }
      }

      &:before {
        content: '';
        display: block;
        padding-bottom: 77%;
      }

      span {
        display: block;
        position: absolute;
        left: 50%; top: 50%;
        text-transform: uppercase;
        transform: translate(-50%, -50%);
        white-space: nowrap;
        font-size: 2.3rem;

        @media $mobile {
          font-size: 1.7rem;
        }

        @media $small-mobile {
          font-size: 1.2rem;
        }
      }
    }
  }

  .stage-content--number {
    text-transform: uppercase;
    font-family: $caption-font;
    font-size: 3.5rem;
    line-height: 1.2;

    @media $mobile {
      font-size: 2.5rem;
    }
  }

  .stage-content--caption {
    text-transform: uppercase;
    font-family: $caption-font;
    font-size: 9.1rem;
    font-weight: bold;
    line-height: 1;

    @media $mobile {
      font-size: 5rem;
    }

    /* @media $small-mobile {
      font-size: 3rem;
    } */

    @media $landscape-mobile {
      font-size: 3rem;
    }
  }

  .stage-content--text {
    display: inline-block;
    padding: 4% 16% 7% 10%;
    font-size: 2rem;
    line-height: 1.2;
    background: url(/i/stage-text-bg.png) no-repeat 50% / contain;

    @media $tablet {
      font-size: 1.7rem;
    }

    @media $mobile {
      font-size: 1.6rem;
      padding: 5% 16% 9% 10%
    }

    /* @media $small-mobile {
      padding: 20px;
    } */

    @media $landscape-mobile {
      padding: 20px;
    }
  }

  &.stage-2 {
    background-image: url(/i/stage-2-bg.jpg);
    background-size: cover;
    min-height: 1200px;

    &:before {
      padding-bottom: 66%;
    }
  }

  &.stage-3 {
    background-image: url(/i/stage-3-bg.jpg);
    background-size: cover;
    min-height: 1200px;

    &:before {
      padding-bottom: 60%;
    }
  }

  &.stage-4 {
    background-image: url(/i/stage-4-bg.jpg);
    background-size: cover;
    min-height: 1200px;

    &:before {
      padding-bottom: 58%;
    }
  }

  &.stage-5 {
    background-image: url(/i/stage-5-bg.jpg);
    background-size: cover;
    min-height: 1133px;

    &:before {
      padding-bottom: 58%;
    }
  }
}

.works-section.stage-3.sony-popup-section {
  padding: 7% 1%;
  box-sizing: border-box;

  @media $notebook {
    padding: 7% 4%;
  }

  @media $handheld {
    padding: 7% 4%;
  }

  @media $mobile {
    padding: 17% 4% 14.5%;
  }

  &:before, &:after {
    content: '';
    display: block;
    width: 100%; height: 50%;
    background: url(/i/cam-frame.png) no-repeat 50% / 100% auto;
    position: fixed;
    left: 0;
    z-index: 100;
    pointer-events: none;

    @media $mobile {
      @media (orientation: portrait) {
        background-size: auto 200%;
      }
    }
  }

  &:before {
    background-position: 50% 0;
    top: 0;
  }

  &:after {
    background-position: 50% 100%;
    bottom: 0;
  }
}

#cube-info {
  .cube-slide-1 {
    background: url(/i/cube-1.jpg) no-repeat 50% / cover;

    @media $mobile {
      min-height: 700px;
    }

    @media $small-mobile {
      min-height: 500px;
    }
  }

  .cube-slide-2 {
    background: url(/i/cube-2.jpg) no-repeat 50% / cover;

    @media $handheld {
      min-height: 900px;
    }

    @media $mobile {
      .stage-content {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  .cube-slide-3 {
    background: url(/i/cube-3.jpg) no-repeat 50% / cover;

    @media $handheld {
      min-height: 850px;
    }

    @media $mobile {
      .stage-content {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  .stage-content--caption {
    margin-bottom: 4%;
  }

  .stage-content {
    margin-top: 0;
    padding-bottom: 50px;
  }

  .bike-pic {
    margin-top: -70px;
  }

  .external-resource-btn {
    @extend .btn, .white-dotted-btn, .h50btn, .s15, .bb, .uppercase;
    padding: 0 10px;

    &:hover {
      background: $white;
    }
  }

  .cube-slide-1,
  .cube-slide-2,
  .cube-slide-3 {
    .left-spots {
      width: 35%;
      padding-bottom: 40%;
      background: url(/i/spots-black.png) no-repeat 0 50% / contain;
      position: absolute;
      left: 0; top: 0;
    }

    .right-spots {
      width: 30%;
      padding-bottom: 50%;
      background: url(/i/spots-blue.png) no-repeat 100% 50% / contain;
      position: absolute;
      right: 0; bottom: 0;
    }
  }
}

#ripcurl-info {
  .ripcurl-slide-1 {
    background: url(/i/ripcurl-1.jpg) no-repeat 50% / cover;

    @media $mobile {
      min-height: 700px;
    }

    @media $small-mobile {
      min-height: 500px;
    }
  }

  @media $mobile {
    @media $not-small-mobile {
      .stage-content--icon {
        margin-top: 15%;
      }
    }
  }

  .stage-content--caption {
    margin-bottom: 4%;

    @media $handheld {
      font-size: 6rem;
    }

    @media $small-mobile {
      font-size: 3rem;
    }
  }

  .item--text {
    background-position: 50%;

    @media $small-mobile {
      padding: 30px 10px;
    }
  }

  .stage-content {
    margin-top: 0;
    padding-bottom: 50px;
  }

  .stage-content--text {
    font-size: 3.5rem;

    @media $small-mobile {
      font-size: 2rem;
    }
  }

  .ripcurl-slide-1 {
    .right-spots-1 {
      width: 60%;
      padding-bottom: 33%;
      background: url(/i/rip-spots-1.png) no-repeat 0 50% / contain;
      position: absolute;
      right: 0; top: -20%;
    }

    .right-spots-2 {
      width: 30%;
      padding-bottom: 50%;
      background: url(/i/rip-spots-2.png) no-repeat 100% 50% / contain;
      position: absolute;
      right: 0; top: 30%;
    }
  }
}

.stages-section--register-btn {
  @extend .btn, .blue-btn, .h62btn, .p45btn, .s26, .bb, .uppercase, .caption-font;
  letter-spacing: 2px;

  @media $small-mobile {
    @extend .white-dotted-btn;
  }

  @media $tablet {
    display: none;
  }

  @media $notebook {
    display: none;
  }

  @media $mobile {
    @media $not-small-mobile {
      display: none;
    }
  }
}

.stages-line {
  background: $blue;
  width: 100%; height: 67px;
  line-height: 67px;
  position: absolute;
  left: 0; bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media $mobile {
    padding: 0 10px;
    box-sizing: border-box;
  }

/*   @media $small-mobile {
    height: auto;
    line-height: auto;
    display: block;
    position: relative;
    padding-bottom: 30px;
  } */

  @media $landscape-mobile {
    height: auto;
    line-height: auto;
    display: block;
    position: relative;
    padding-bottom: 30px;
  }

  &:after {
    content: '';
    display: block;
    width: 86px; height: 36px;
    background: url(/i/heli.png) no-repeat 50%;
    position: absolute;
    bottom: 100%;
    left: calc(50% + 470px);
  }

  .stage {
    position: relative;
    line-height: 1.2;
    text-align: center;
    color: #1c4c6f;
    text-decoration: none;

    /* @media $not-small-mobile { */
    @media $not-landscape-mobile {
      &:not(:last-child) {
        padding-right: 47px;

        &:before, &:after {
          content: '';
          display: block;
          width: 1px; height: 18px;
          background: $white;
          position: absolute;
          right: 0;
        }

        &:before {
          top: 50%;
          transform: rotate(45deg);
          transform-origin: top right;
          margin-top: 4px;
        }

        &:after {
          bottom: 50%;
          transform: rotate(-45deg);
          transform-origin: bottom right;
          margin-bottom: -4px;
        }
      }

      + .stage {
        margin-left: 27px;
      }
    }

    /* @media $small-mobile {
      + .stage {
        margin-top: 20px;
      }
    } */

    @media $landscape-mobile {
      + .stage {
        margin-top: 20px;
      }
    }

    &.active {
      color: $white;

      .stage--caption {
        font-size: 2.3rem;
      }
    }

    &.disabled {
      * {
        opacity: .7;
      }
    }
  }

  .stage--icon {
    width: 75px; height: 75px;
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;

    /* @media $not-small-mobile { */
    @media $not-landscape-mobile {
      position: absolute;
      bottom: 100%; left: 50%;
      transform: translateX(-50%);
      margin-bottom: 20px;
    }

    /* @media $small-mobile {
      width: 100%;
      margin-left: 0 !important;
    } */

    @media $landscape-mobile {
      width: 100%;
      margin-left: 0 !important;
    }
  }

  .stage:not(:last-child) .stage--icon {
    margin-left: -20px;
  }

  .stage--number {
    font-family: $caption-font;
    font-size: 1.3rem;
    text-transform: uppercase;
    line-height: 1.8rem;
  }

  .stage--caption {
    font-family: $caption-font;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 2.2rem;
  }

  for $number in (1..5) {
    $pic = '/i/stage-' + $number + '.svg';
    $active-pic = '/i/stage-' + $number + '-active.svg';

    .stage-{$number} .stage--icon {
      background-image: url($pic);
    }

    .stage-{$number}.active .stage--icon {
      background-image: url($active-pic);
    }
  }
}

.short-rules-section {
  width: 100%;
  padding-bottom: 30%;
  position: relative;
  margin-top: -20px;
  margin-bottom: -7%;
  z-index: 30;

  @media $small-mobile {
    padding-bottom: 0;
    margin-bottom: 0;
    background: $white;
  }

  &:before {
    content: '';
    display: block;
    background: #3c8fcb;
    width: 100%; height: 73%;
    position: absolute;
    left: 0; bottom: 7%;
    z-index: -1;

    @media $mobile {
      height: 60%;
      bottom: 21%;
    }

    @media $small-mobile {
      display: none;
    }
  }

  &:after {
    content: '';
    display: block;
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
    background: url(/i/stages-top.png) no-repeat 50% 0 / 100% auto;

    @media $small-mobile {
      display: none;
    }
  }

  .wrapper {
    width: 100%;
    position: absolute;
    left: 50%; top: 0;
    z-index: 10;
    transform: translateX(-50%);
    padding-top: 65px;

    @media $tablet {
      padding-top: 25px;
    }

    @media $small-mobile {
      padding-top: 30px;
      padding-bottom: 30px;
      position: static;
      transform: none;
    }

    @media $not-small-mobile {
      @media $landscape-mobile {
        padding-top: 10px;
      }
    }
  }

  @media $small-mobile {
    background: $white;
    height: auto;
    position: relative;
  }
}

.short-rules--info {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: 80px;

  @media $notebook {
    margin-bottom: 30px;
  }

  @media $tablet {
    margin-bottom: 30px;
  }

  @media $mobile {
    margin-bottom: 15px;
  }

  @media $small-mobile {
    flex-direction: column;
    align-items: center;
  }

  @media $not-small-mobile {
    @media $landscape-mobile {
      margin-bottom: 10px;
    }
  }

  .item {
    flex: 1;
    max-width: 320px;

    + .item {
      margin-left: 10px;
    }

    @media $small-mobile {
      width: 100%;

      + .item {
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }

  .item--icon {
    padding-bottom: 65%;
    margin-bottom: 10px;

    @media $notebook {
      padding-bottom: 46%;
    }

    @media $tablet {
      padding-bottom: 46%;
    }

    @media $mobile {
      padding-bottom: 39%;
    }
  }

  .item-register .item--icon {
    background: url(/i/prestages-icon-1.png) no-repeat 50% / contain;
  }

  .item-trial .item--icon {
    background: url(/i/prestages-icon-2.png) no-repeat 50% / contain;
  }

  .item-video .item--icon {
    background: url(/i/prestages-icon-3.png) no-repeat 50% / contain;
  }

  .item-prize .item--icon {
    background: url(/i/prestages-icon-4.png) no-repeat 50% / contain;
  }

  .item--caption {
    font-family: $caption-font;
    font-size: 2.6rem;
    font-weight: bold;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    color: $black;

    @media $notebook {
      font-size: 2rem;
    }

    @media $tablet {
      font-size: 2rem;
    }

    @media $mobile {
      font-size: 1.5rem;
    }

    @media $not-small-mobile {
      @media $landscape-mobile {
        font-size: 1.2rem;
      }
    }
  }
}

.short-rules--rules-link {
  @extend .btn, .blue-dotted-btn, .h50btn, .caption-font, .s19, .uppercase, .bb;
  width: 212px;

  @media $mobile {
    font-size: 1.5rem;
  }

  @media $not-small-mobile {
    @media $landscape-mobile {
      line-height: 38px;
    }
  }
}

.judges-section {
  padding: 60px 0 100px;
  position: relative;

  .wrapper {
    position: relative;
    z-index: 10;
  }
}

.judges-section--bg {
  width: 100%; height: 100vh;
  position: absolute;
  left: 0; top: 0;
  z-index: -1;
  background: url(/i/bg-4.jpg) no-repeat 50% / cover;
}

.judges-section--caption {
  font-family: $caption-font;
  font-size: 9.1rem;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;

  @media $mobile {
    font-size: 7.5rem;
  }

  @media $small-mobile {
    font-size: 3.5rem;
    margin-bottom: 40px;
  }
}

.judges-section--bg-line {
  width: 21%;
  padding-bottom: 8%;
  background: url(/i/judges-bg-line.png) no-repeat 0 50% / contain;
  position: absolute;
  left: 0; top: 6%;
}

.judges-list {
  .judge {
    display: flex;
    align-items: center;

    @media $small-mobile {
      flex-direction: column;
    }

    + .judge {
      margin-top: 100px;

      @media $tablet {
        margin-top: 50px;
      }

      @media $small-mobile {
        margin-top: 20px;
      }
    }

    .inner {
      flex: 1;

      .item-part {
        display: flex;

        @media $small-mobile {
          flex-direction: column;
        }

        + .item-part {
          margin-top: 45px;

          @media $small-mobile {
            margin-top: 0;
          }
        }
      }
    }
  }

  .judge--photo {
    width: 24%;
    /* padding-bottom: 24%; */
    position: relative;
    flex-shrink: 0;

    @media $small-mobile {
      width: 36%;
      /* padding-bottom: 36%; */
    }

    /* img {
      position: absolute;
      left: 0; top: 0;
    } */
  }

  .judge.heroes-caption {
    .judge--photo {
      width: 37px;
    }

    .judge--name {
      font-size: 5.7rem;
      width: calc(24% + 283px);

      @media $mobile {
        font-size: 2.6rem;
        width: auto;
      }

      @media $small-mobile {
        width: 100%;
      }
    }

    .judge--text {
      background: none;
    }
  }

  .judge--name {
    font-family: $caption-font;
    font-size: 3.6rem;
    font-weight: bold;
    text-transform: uppercase;
    padding-right: 25px;
    padding-left: 30px;
    width: 320px;
    max-width: 100%;
    flex-shrink: 0;

    @media $mobile {
      width: 21%;
      font-size: 2.6rem;
    }

    @media $not-small-mobile {
      @media $landscape-mobile {
        width: 24%;
      }
    }

    @media $small-mobile {
      width: 100%;
      text-align: center;
      margin-top: 5px;
      box-sizing: border-box;
    }
  }

  .judge--text {
    display: inline-flex;
    align-items: center;
    padding-left: 45px;
    background: url(/i/judge-line.png) no-repeat 0 50%;
    min-height: 86px;
    line-height: 1.5;

    @media $small-mobile {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      box-sizing: border-box;
      background: none;
      text-align: center;
      display: block;
      margin-top: 5px;
    }
  }
}

.smokin-heroes--poster {
  padding-bottom: 50%;
  background: url(/i/smokin-heroes.png) no-repeat 50% / contain;
}

.smokin-heroes--info {
  .item {
    display: flex;
    align-items: center;

    @media $small-mobile {
      flex-direction: column;
    }

    @media $not-mobile {
      flex-wrap: wrap;
      align-items: flex-start;
    }

    + .item {
      margin-top: 30px;
    }
  }

  @media $not-mobile {
    display: flex;

    .item {
      flex: 1;

      + .item {
        margin: 0 0 0 30px;
      }
    }
  }

  .item--icon {
    flex-shrink: 0;

    @media $not-small-mobile {
      @media $landscape-mobile {
        width: 15%;
      }
    }

    @media $small-mobile {
      width: 36%;
    }
  }

  .item--name {
    font-family: $caption-font;
    font-size: 3.6rem;
    font-weight: bold;
    text-transform: uppercase;
    padding-right: 25px;
    padding-left: 50px;
    width: 350px;
    max-width: 100%;
    flex-shrink: 0;

    @media $not-mobile {
      width: calc(100% - 178px);
      padding-right: 0;
      box-sizing: border-box;
      align-self: center;
    }

    @media $mobile {
      padding-left: 30px;
      padding-right: 0;
      width: 21%;
      font-size: 2.6rem;
    }

    @media $not-small-mobile {
      @media $landscape-mobile {
        width: 24%;
      }
    }

    @media $small-mobile {
      width: 100%;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 5px;
      text-align: center;
      box-sizing: border-box;
    }
  }

  .item--text {
    display: inline-flex;
    align-items: center;
    padding-left: 45px;
    background: url(/i/judge-line.png) no-repeat 0 50%;
    min-height: 86px;
    line-height: 1.5;

    @media $not-mobile {
      margin-top: 20px;
    }

    @media $small-mobile {
      margin-top: 5px;
      display: block;
      width: 100%;
      text-align: center;
      padding-left: 0;
      background: none;
    }
  }
}

.registration-section {
  position: relative;
  padding: 80px 0 100px;

  .wrapper {
    position: relative;
    z-index: 10;
  }
}

.registration-section--bg {
  width: 100%; height: 100vh;
  position: absolute;
  left: 0; top: 0;
  background: url(/i/bg-5.jpg) no-repeat 50% / cover;
}

.registration-section--caption {
  font-family: $caption-font;
  font-size: 7.5rem;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 60px;

  @media $small-mobile {
    font-size: 3rem;
  }
}

.registration-section--rules {
  margin-top: -30px;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;

  li {
    list-style: none;
    padding-left: 50px;
    background: url(/i/list-mark.png) no-repeat;
    padding-top: .5em;
    min-height: 32px;
    box-sizing: border-box;

    + li {
      margin-top: 1em;
    }
  }

  p + p {
    margin-top: 1.5em;
  }

  a {
    color: $blue;
    text-decoration: none;
    border-bottom: 2px dotted $blue;
  }
}

.registration-popup,
.subscribe-popup {
  box-sizing: border-box;
  width: $wrapper-width;
  max-width: 100%;
  min-height: 100vh;
  background: url(/i/summer/popup-bg.png) no-repeat 50% 0;
  padding-top: 100px;
  top: 0 !important;

  @media $small-mobile {
    padding-top: 80px;
  }

  .wrapper {
    position: relative;
  }

  &--caption {
    font-family: $caption-font;
    font-size: 4rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }

  .popup--close {
    width: auto;
    padding-right: 33px;
    line-height: 46px;
    color: $gray;
    text-transform: uppercase;
    top: 10px; right: 25px;

    &:before, &:after {
      left: auto; right: 0;
    }

    @media $small-mobile {
      margin-top: -50px;
    }
  }
}

@media $landscape-mobile {
  @media $not-small-mobile {
    .subscribe-popup {
      height: 100%;
    }

    .subscribe-popup {
      .registration-popup--caption {
        font-size: 3rem;
      }
    }

    .success-popup {
      width: 100%; height: 100%;
    }
  }
}

.registration-form,
.subscribe-form {
  width: 550px;

  .form-row {
    margin-bottom: 0;

    + .form-row {
      margin-top: 15px;

      @media $small-mobile {
        margin-top: 7px;
      }
    }
  }

  .form-label {
    float: left;
    width: 160px;
    padding-right: 25px;
    text-align: right;
    margin-top: .75em;

    @media $small-mobile {
      width: 100%;
      text-align: center;
      float: none;
      padding-right: 0;
      margin-top: 0;
      margin-bottom: 5px;
    }

    label {
      font-size: 1.6rem;

      @media $small-mobile {
        font-size: 1.2rem;
      }
    }
  }

  .form-field {
    width: calc(100% - 185px);

    @media $small-mobile {
      width: 100%;
    }

    .inp {
      font-size: 1.6rem;
      padding-top: .85em;
      padding-bottom: .85em;
      border-color: $white;

      &:focus {
        border-color: $green;
      }
    }
  }

  .form-submit {
    padding-left: 185px;
    margin-top: 40px;

    @media $small-mobile {
      padding-left: 0;
      text-align: right;
    }

    .submit-btn {
      background: rgba($green, .7);
      font-size: 1.9rem;
      line-height: 50px;
      padding: 0 20px;

      &:hover {
        background: $green;
      }
    }
  }
}

.registration-popup--caption {
  margin-bottom: 50px;
}

.subscribe-form .form-submit {
  margin-top: 55px;
}

@keyframes snowfall {
  0%   { transform: scale(1); }
  50%  { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.socials-slide {
  background: $dark url(/i/summer/sm-bg.jpg) no-repeat 50% 100%;

  .news-slide--caption {
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 236px; height: 1px;
      background: $white;
      position: absolute;
      left: 50%; bottom: 100%;
      transform: translateX(-50%);
      margin-bottom: 30px;
    }
  }
}

.social-section {
  /* background: url(/i/bg-6.jpg) no-repeat 50% 0% / cover; */
  position: relative;
  overflow: hidden;
  padding-top: 150px;
  padding-bottom: 220px;

  @media $small-mobile {
    padding-top: 50px;
    padding-bottom: 250px;
  }

  /* &:after {
    content: '';
    display: block;
    width: 50%;
    padding-bottom: 18%;
    background: url(/i/social-brush.png) no-repeat 100% 50% / contain;
    position: absolute;
    right: 0; bottom: 0;
  }

  &:before {
    content: '';
    display: block;
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
    background: url(/i/social-snow.png) no-repeat 50% / cover;
    animation: snowfall infinite 10s;
    pointer-events: none;
  } */

/*   .inner {
    position: absolute;
    left: 50%; top: 50%;
    z-index: 10;
    transform: translate(-50%, -50%);
    text-align: center;
    white-space: nowrap; */

    .ya-share2__list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 100%;
      width: 940px;
      margin-left: auto;
      margin-right: auto;

      @media $mobile {
        width: 100%;
      }

      li {
        list-style: none;
        font-size: 0;
        flex: 1;
        margin: 5%;

        a {
          text-decoration: none;
          display: block;
          padding-bottom: 100%;
          transition: all .25s ease;

          &:hover {
            opacity: .75;
          }
        }

        + li {
          margin-left: 20px;

          @media $tablet {
            margin-left: 40px;
          }
        }
      }
    }

    .ya-share2__item_service_vkontakte {
      a {
        background: url(/i/sm-vk-big.png) no-repeat 50% / contain;
      }
    }

    .ya-share2__item_service_facebook {
      a {
        background: url(/i/sm-fb-big.png) no-repeat 50% / contain;
      }
    }

    .ya-share2__item_service_twitter {
      a {
        background: url(/i/sm-twit-big.png) no-repeat 50% / contain;
      }
    }

    .ya-share2__item_service_odnoklassniki {
      a {
        background: url(/i/sm-ok-big.png) no-repeat 50% / contain;
      }
    }
  /* } */
}

.social-section--caption {
  font-family: $caption-font;
  font-weight: bold;
  font-size: 4rem;
  line-height: 1.2;
  text-transform: uppercase;
  margin-bottom: -50px;
  text-align: center;

  @media $mobile {
    font-size: 3.6rem;
  }

  @media $landscape-mobile {
    font-size: 2.6rem;
  }

  @media $small-mobile {
    font-size: 2rem;
    margin-bottom: -20px;
  }
}

.faq-section {
  position: relative;
  padding-bottom: 80px;
  padding-top: 80px;
  overflow: hidden;

  .inner {
    position: relative;
    z-index: 10;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.5rem;
    line-height: 1.5;
    color: $gray;

    @media $small-mobile {
      padding: 0 10px;
      box-sizing: border-box;
      width: 100%;
    }

    .h3 {
      color: $white;
    }
  }

  .faq-content {
    display: none;
    margin-top: 40px;
    margin-bottom: 50px;
  }

  .toggle-faq-btn {
    @extend .btn, .white-dotted-btn, .h50btn, .s20, .bb, .caption-font, .uppercase;
    width: 212px;

    &:hover {
      background: $white;
    }

    &:not(.active) .expanded-text {
      display: none;
    }

    &.active .collapsed-text {
      display: none;
    }
  }
}

.faq-section--bg {
  width: 100%; height: 100vh;
  position: absolute;
  left: 0; top: 0;
  background: url(/i/bg-5.jpg) no-repeat 50% / cover;

  .is-collapsed & {
    position: absolute !important;
  }
}

.faq-section--caption {
  font-family: $caption-font;
  font-size: 7.5rem;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
  position: relative;
  z-index: 10;

  @media $small-mobile {
    font-size: 5rem;
  }
}

.faq-section--bg-line-1 {
  width: 27%;
  padding-bottom: 8%;
  background: url(/i/faq-bg-line-1.png) no-repeat 0 50% / contain;
  position: absolute;
  left: 0; top: 6%;

  @media $small-mobile {
    display: none;
  }
}

.faq-section--bg-line-2 {
  width: 24%;
  padding-bottom: 8%;
  background: url(/i/faq-bg-line-2.png) no-repeat 100% 50% / contain;
  position: absolute;
  right: 0; top: 22%;

  @media $small-mobile {
    display: none;
  }
}


.success-popup {
  position: fixed;
  width: 100vw; height: 100vh;
  left: 0 !important; top: 0 !important;
  background: rgba($black, .8);

  .inner {
    width: $wrapper-width;
    max-width: 100%;
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  @media $small-mobile {
    .popup--close {
      top: -40px;
    }
  }
}

.success-popup--caption {
  width: $wrapper-width;
  max-width: 100%;
  padding-bottom: 23%;
  transform: translateX(-10%);
  margin-left: auto;
  margin-right: auto;
  background: url(/i/summer/success-caption.png) no-repeat 50% / contain;
}

.subscribe-success-popup .success-popup--caption {
  width: $wrapper-width;
  max-width: 100%;
  padding-bottom: 23%;
  transform: translateX(-10%);
  margin-left: auto;
  margin-right: auto;
  background: url(/i/summer/subscribe-success-caption.png) no-repeat 50% / contain;
}

.success-popup--text {
  font-family: $caption-font;
  font-weight: bold;
  font-size: 2rem;
  color: $gray;
  text-transform: uppercase;
  text-align: center;
  margin-top: -35px;

  @media $mobile {
    margin-top: -15px;
  }

  @media $small-mobile {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1.3rem;
  }
}

.success-popup--logo {
  width: 42%;
  padding-bottom: 18%;
  margin-left: auto;
  margin-right: auto;
  background: url(/i/marathon-logo.svg) no-repeat 50% / contain;
}

.success-popup--back-btn {
  @extend .btn, .white-dotted-btn, .h50btn, .caption-font, .s19, .uppercase, .bb;
  margin-top: 30px;
  padding: 0 40px 0 20px;

  &:hover {
    color: $green;

    &:before {
      border-color: $green;
    }

    .back-icon {
      &:before, &:after {
        background: $green;
      }
    }
  }
}

.rules-section {
  display: none;
  position: relative;
  background: #0091e5 url(/i/bg-7.jpg) no-repeat 50% 0 / 100% auto;
  padding-bottom: 50px;
  padding-top: 120px;

  @media $small-mobile {
    padding-top: 85px;
  }

  .rules-spots {
    width: 42%;
    padding-bottom: 40%;
    position: absolute;
    top: -15%; left: 0;
    z-index: 20;
    background: url(/i/stages-spots.png) no-repeat 0 50% / contain;
  }

  .inner {
    position: relative;
    z-index: 10;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.5rem;
    line-height: 1.5;

    a {
      word-break: break-all;
    }

    @media $small-mobile {
      padding: 0 10px;
      box-sizing: border-box;
      width: 100%;
    }

    p {
      position: relative;
      padding-top: 28px;
      min-height: 3rem;

      @media $mobile {
        padding-left: 100px;
      }

      @media $small-mobile {
        padding-left: 60px;
        padding-top: 16px;
      }
    }

    .counter {
      display: block;
      box-sizing: border-box;
      width: 72px; height: 69px;
      padding-left: 5px;
      line-height: 72px;
      text-align: center;
      position: absolute;
      left: -100px; top: 0;
      background: url(/i/sign.png) no-repeat 50% / contain;
      font-family: $caption-font;
      font-weight: bold;
      font-size: 2rem;

      @media $mobile {
        left: 0;
      }

      @media $small-mobile {
        width: 50px; height: 50px;
        line-height: 50px;
        font-size: 1.5rem;
      }
    }
  }

  .rules-content {
    display: none;
    margin-bottom: 50px;
  }

  .toggle-rules-btn {
    @extend .btn, .white-dotted-btn, .h50btn, .s20, .bb, .caption-font, .uppercase;
    width: 212px;

    &:hover {
      background: $white;
    }
  }
}

.rules-section--logo {
  position: relative;
  width: 55%;
  padding-bottom: 34%;
  background: url(/i/intro-logo.png) no-repeat 50% / contain;
  margin-bottom: 70px;

  @media $not-mobile {
    margin-left: -110px;
  }

  @media $small-mobile {
    width: 100%;
    padding-bottom: 42%;
    margin-bottom: 40px;
  }
}

.rules-section-logo--link {
  display: block;
  width: 38%; height: 42%;
  position: absolute;
  right: 0; top: 34%;
}

.rules-section--caption {
  display: block;
  width: 50%;
  padding-bottom: 14%;
  background: url(/i/rules-caption.png) no-repeat 100% 0 / contain;
  position: absolute;
  right: 0; top: 120px;

  @media $small-mobile {
    width: 84%;
    padding-bottom: 25%;
    top: 0;
  }
}

.rules-section--bg-line {
  width: 27%;
  padding-bottom: 8%;
  background: url(/i/rules-bg-line.png) no-repeat 0 50% / contain;
  position: absolute;
  left: 0; top: 10%;

  @media $handheld {
    display: none;
  }
}

.intro-section + .news-slide {
  overflow: hidden;
  position: relative;
  padding-bottom: 0;

  @media $small-mobile {
    margin-top: -130px;
    z-index: 10;
  }

  &:after {
    content: '';
    display: block;
    width: 120%; height: 50%;
    background: $dark;
    position: absolute;
    right: -10%; bottom: 0;
    transform: rotate(-5deg);
    transform-origin: right bottom;
    z-index: 0;
  }

  > * {
    position: relative;
    z-index: 1;
  }
}

.news-slide--caption {
  font-family: $caption-font;
  font-weight: bold;
  font-size: 5rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 35px;
  position: relative;

  h1 {
    font-size: 5rem;
  }

  &, a {
    color: $gray;
  }

  .intro-section + .news-slide & {
    &:before {
      content: '';
      display: block;
      width: 236px; height: 1px;
      background: $black;
      position: absolute;
      left: 50%; bottom: 100%;
      transform: translateX(-50%);
      margin-bottom: 30px;
    }

    &, a {
      color: $black;
    }
  }

  @media $handheld {
    margin-bottom: 55px;
  }

  @media $mobile {
    font-size: 4rem;
  }

  @media $small-mobile {
    font-size: 2.7rem;
  }

  .subscribe-btn {
    vertical-align: middle;
    margin-left: 70px;

    @media $small-mobile {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}

.subscribe-btn {
  @extend .btn, .black-dotted-btn, .h50btn;
  padding: 0 90px 0 25px;
  font-size: 1.6rem;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 23px; height: 14px;
    background: url(/i/mail-icon.png) no-repeat 50%;
    position: absolute;
    right: 30px; top: 50%;
    transform: translateY(-50%);
  }
}

.instawidget-wrapper {
  position: relative;
  height: 100%;
}

.instawidget--caption {
  position: absolute;
  bottom: 100%; left: 0;
  font-size: 2.4rem;
  text-decoration: none;
  padding-left: 64px;
  background: url(/i/inst-icon.png) no-repeat 0 50%;
  line-height: 42px;
  margin-bottom: 10px;

  @media $small-mobile {
    font-size: 1.8rem;
  }
}

.instawidget {
  position: relative;
  white-space: nowrap;
  font-size: 0;
  left: 0;
  transition: left .3s ease;
}

.instapage {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  white-space: normal;
  /* opacity: .25; */
  /* transition: opacity .3s ease; */
  display: none;

  &.active {
    display: inline-block;
  }

  img {
    width: 25%;

    @media $small-mobile {
      width: 50%;
    }
  }
}

.inst-prev-page,
.inst-next-page {
  display: block;
  width: 58px; height: 76px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &.disabled {
    display: none;
  }
}

.inst-prev-page {
  right: 100%;
  background: url(/i/arrow-left.png) no-repeat 50%;
}

.inst-next-page {
  left: 100%;
  background: url(/i/arrow-right.png) no-repeat 50%;
}

.marathon-table {
  white-space: nowrap;
  font-size: 12px;

  th, td {
    padding: 2px 5px;
  }

  th + th {
    font-size: 10px;
  }

  td + td, th + th {
    text-align: center;
    border-left: 1px solid lighten($gray, 10);
  }

  tr + tr {
    border-top: 1px solid lighten($gray, 10);
  }
}