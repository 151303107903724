
/*   -=========== Partners ===========-   */

.prizes-intro {
  .intro-caption {
    background-image: url(/i/partners-caption.png);
  }

  &.inview .intro-caption {
    top: 10%;
  }
}

.partners-section {
  height: 1050px;
  position: relative;

  @media $small-mobile {
    height: auto;
    padding-top: 260px;
    background-size: auto 260px !important;
    background-position: 50% 0 !important;
  }

  .inner {
    display: block;
    padding: 4% 16% 7% 10%;
    font-size: 2rem;
    line-height: 1.2;
    background: url(/i/stage-text-bg.png) no-repeat 50%/contain;
    position: absolute;
    left: 50%; top: 58%;
    transform: translate(-50%, -50%);
    width: 750px;
    max-width: 100%;
    text-align: center;
    position: relative;

    @media $tablet {
      span {
        background: $blue;
      }
    }

    @media $mobile {
      box-sizing: border-box;
    }

    @media $small-mobile {
      width: 100%;
      position: static;
      transform: none;
      background: $blue;
      padding: 15px;
      font-size: 1.4rem;
    }
  }

  $sponsors-list = ripcurl, cube, redbull, sony, bolle, reusch, birdy, riskru;

  for $sponsor in $sponsors-list {
    &.{$sponsor} {
      background: url(/i/partners- $sponsor .jpg) no-repeat 50% / cover;
    }
  }

  @media $not-small-mobile {
    + .partners-section {
      &:before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 13%;
        background: url(/i/between-screens.png) no-repeat 50% / contain;
        position: absolute;
        top: -10%; left: 0;
      }
    }
  }
}

.partners-section--site-link {
  position: absolute;
  left: 50%; top: 100%;
  transform: translateX(-50%);
  margin-top: -30px;
  @extend .btn, .white-dotted-btn, .h50btn, .p30btn, .caption-font, .s20, .bb, .uppercase;
  background: rgba($black, .6);
  white-space: nowrap;

  @media $small-mobile {
    position: relative;
    top: auto; left: auto;
    transform: none;
    margin-top: 20px;
  }
}