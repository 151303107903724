
/*   -=========== Prizes ===========-   */

.prize-section-1,
.prize-section-1-2,
.prize-section-2,
.prize-section-3,
.prize-section-3-2,
.prize-section-4,
.prize-section-4-2,
.prize-section-5 {
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
  }

  .inner {
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;
    /* overflow: hidden; */

    @media $small-mobile {
      overflow: hidden;
    }

    .wrapper {
      height: 100%;
      position: relative;
    }
  }
}

.prize-section-1 {
  background: url(/i/prize-bg-1.jpg) no-repeat 50% / cover;

  @media $small-mobile {
    background-position: 50% 100%;
    background-size: 100% auto;
    background-color: $blue;
  }

  &:before {
    padding-bottom: 46.5%;

    @media $small-mobile {
      padding-bottom: 60%;
    }
  }

  .prize-caption {
    width: 57%;
    padding-bottom: 20%;
    position: absolute;
    left: 45%; top: 10%;
    transform: translateX(-50%);
    background: url(/i/prize-caption-1-in.png) no-repeat 50% / contain;
    font-size: 0;

    @media $notebook {
      top: 15%;
    }

    @media $tablet {
      top: 18%;
    }

    @media $mobile {
      top: 23%;
    }

    @media $small-mobile {
      top: 34%;
      width: 110%;
      padding-bottom: 38%;
    }

    .prize-bg {
      width: 100%; height: 100%;
      position: absolute;
      left: 0; top: 0;
      clip-path: url(#prize-clip-path-1);
      overflow: hidden;

      &:before {
        content: '';
        display: block;
        width: 100%; height: 100%;
        position: absolute;
        left: 50%; top: 50%;
        background: url(/i/prize-caption-1-bg.jpg) no-repeat 50% / 100% auto;
        animation: prize-1-caption-animation infinite 12s linear;
        will-change: transform;
      }
    }

    @keyframes prize-1-caption-animation {
      /* 0%   { transform: translate(-50%, -50%) rotate(0deg); }
      100% { transform: translate(-50%, -50%) rotate(360deg); } */
      0%   { transform: translate(-50%, -50%) scale(1) rotate(0deg); }
      50%  { transform: translate(-50%, -50%) scale(1.1) rotate(5deg); }
      100% { transform: translate(-50%, -50%) scale(1) rotate(0deg); }
    }
  }

  .info-icon {
    width: 66px; height: 60px;
    background: url(/i/info-icon.png) no-repeat 50%;
    position: absolute;
    left: 53%; top: 3%;
    cursor: pointer;

    @media $tablet {
      top: -2%;
      width: 50px; height: 50px;
      background-size: contain;
    }

    @media $mobile {
      top: -20%;
    }

    @media $small-mobile {
      width: 40px; height: 40px;
      background-size: contain;
      top: -14%;
    }
  }

  .scrollmagic-pin-spacer {
    width: 100% !important;
  }

  .prize-spots-1 {
    width: 27%;
    padding-bottom: 20%;
    position: absolute;
    top: 0; right: 0;
    background: url(/i/prize-spots-1.png) no-repeat 100% 0 / contain;
  }

  .prize-spots-2 {
    width: 30%;
    padding-bottom: 37%;
    position: absolute;
    left: 0; top: 14%;
    background: url(/i/prize-spots-2.png) no-repeat 0 50% / contain;
  }

  .prize-text {
    position: absolute;
    top: 40%; left: 50%;
    transform: translateX(-50%);
    background: url(/i/stage-text-bg.png) no-repeat 50% / contain;
    width: 1170px;
    max-width: 100%;
    padding: 0 12% 9% 7%;
    box-sizing: border-box;

    @media $mobile {
      padding-bottom: 20%;
      background-size: cover;
    }

    @media $not-small-mobile {
      display: flex;
      justify-content: space-around;
    }

    @media $small-mobile {
      padding: 20px 10px;
      top: 17%;
      background: $blue;

      br {
        display: none;
      }
    }

    .item {
      padding-top: 136px;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1.2;
      text-align: center;
      max-width: 220px;
      font-family: $caption-font;
      flex: 1;

      @media $small-mobile {
        max-width: 100%;

        + .item {
          margin-top: 20px;
        }
      }

      @media $not-small-mobile {
        + .item {
          margin-left: 20px;
        }
      }
    }

    .item-1 {
      background: url(/i/place-1.png) no-repeat 50% 0;
      font-size: 3.1rem;

      @media $mobile {
        font-size: 2rem;
      }
    }

    .item-2 {
      background: url(/i/place-2.png) no-repeat 50% 0;
      font-size: 1.7rem;

      @media $mobile {
        font-size: 1.5rem;
      }
    }

    .item-3 {
      background: url(/i/place-3.png) no-repeat 50% 0;
      font-size: 1.7rem;

      @media $mobile {
        font-size: 1.5rem;
      }
    }
  }
}

.prize-section-1-2 {
  background: url(/i/prize-bg-1-2.jpg) no-repeat 50% / cover;

  &:before {
    padding-bottom: 50%;

    @media $small-mobile {
      padding-bottom: 130%;
    }
  }

  .place-2,
  .place-3 {
    width: 27%;
    padding-bottom: 19%;
    position: absolute;
    top: 21%;
    z-index: 10;

    .place-text {
      position: absolute;
      left: 0; top: 55%;
      box-sizing: border-box;
      width: 100%;
      color: $white;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
      font-family: $caption-font;
      font-size: 2.1rem;
      line-height: 1.2;

      @media only screen and (max-width: 1800px) {
        font-size: 1.7rem;
        padding: 0 7%;

        br {
          display: none;
        }
      }

      @media $notebook {
        font-size: 1.4rem;
      }

      @media $tablet {
        font-size: 1.2rem;
      }

      @media $mobile {
        span {
          background: $blue;
        }
      }
    }
  }

  .place-2 {
    background: url(/i/place-2-text-bg.png) no-repeat 50% / contain;
    right: 52%;
  }

  .place-3 {
    background: url(/i/place-3-text-bg.png) no-repeat 50% / contain;
    left: 52%;
  }

  .prize-item {
    position: absolute;

    a {
      font-size: 0;
      text-decoration: none;
      position: absolute;
      width: 146px; height: 131px;

      @media $mobile {
        width: 100px; height: 100px;
      }
    }
  }

  .prize-reusch {
    width: 30%;
    height: 56%;
    background: url(/i/reusch.png) no-repeat 50% / contain;
    left: 8%; top: -5%;
    z-index: 15;

    a {
      background: url(/i/reusch-link.png) no-repeat 50% / contain;
      right: 2%; top: 21%;

      @media $mobile {
        top: 10%;
      }
    }
  }

  .prize-ripcurl {
    width: 50%;
    height: 95%;
    background: url(/i/ripcurl.png) no-repeat 50% / contain;
    right: 8%; top: 5%;
    z-index: 5;

    a {
      background: url(/i/ripcurl-link.png) no-repeat 50% / contain;
      left: 36%; top: 1%;

      @media $mobile {
        top: -5%;
      }
    }
  }

  .prize-sony {
    width: 30%;
    height: 51%;
    background: url(/i/sony.png) no-repeat 50% / contain;
    left: 15%; bottom: 2%;
    z-index: 15;

    a {
      background: url(/i/sony-link.png) no-repeat 50% / contain;
      left: 51%; top: 22%;
      width: 117px; height: 107px;

      @media $mobile {
        width: 80px; height: 80px;
      }
    }
  }

  .prize-bolle-mask {
    width: 34%;
    height: 59%;
    background: url(/i/bolle-mask.png) no-repeat 50% / contain;
    right: 18%; bottom: -5%;
    z-index: 15;

    a {
      background: url(/i/bolle-link.png) no-repeat 50% / contain;
      left: 37%; top: 24%;
    }
  }

  @media $not-small-mobile {
    .mobile-inner {
      display: none;
    }
  }

  @media $small-mobile {
    background: #0097cf;

    &:before {
      display: none;
    }

    .inner {
      display: none;
    }
  }

  .mobile-inner {
    padding: 20px 10px;
    font-size: 1.4rem;
    font-family: $caption-font;
    font-weight: bold;
  }

  .mobile-prizes {
    position: relative;
    background: url(/i/mobile-prizes-1.jpg) no-repeat 50%;
    padding-bottom: 300%;
    margin-left: -10px;
    margin-right: -10px;

    .prize-item {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
      background: none;
      width: auto; height: auto;

      a {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .prize-ripcurl {
      top: 0;
    }

    .prize-reusch {
      top: 28%;
    }

    .prize-sony {
      top: 36%;
    }

    .prize-bolle-mask {
      top: 63%;
    }
  }
}

.prize-section-2 {
  background: url(/i/prize-bg-2.jpg) no-repeat 50% / cover;

  @media $small-mobile {
    background-size: 100% auto;
    background-position: 50% 0;
  }

  &:before {
    padding-bottom: 63%;

    @media $small-mobile {
      padding-bottom: 140%;
    }
  }

  .prize-caption {
    width: 57%;
    padding-bottom: 20%;
    position: absolute;
    left: 45%; top: 16%;
    transform: translateX(-50%);
    background: url(/i/prize-caption-2.png) no-repeat 50% / contain;
    font-size: 0;

    @media $small-mobile {
      width: 110%;
      padding-bottom: 37%;
      top: 4%; left: 40%;
    }

    .prize-bg {
      width: 100%; height: 100%;
      position: absolute;
      left: 0; top: 0;
      clip-path: url(#prize-clip-path-2);
      overflow: hidden;

      &:before {
        content: '';
        display: block;
        width: 100%; height: 100%;
        position: absolute;
        left: 50%; top: 50%;
        background: url(/i/prize-caption-2-bg.jpg) no-repeat 50% / 100% auto;
        animation: prize-1-caption-animation infinite 16s linear;
        will-change: transform;
      }
    }

    @keyframes prize-1-caption-animation {
      /* 0%   { transform: translate(-50%, -50%) rotate(0deg); }
      100% { transform: translate(-50%, -50%) rotate(360deg); } */
      0%   { transform: translate(-50%, -50%) scale(1) rotate(0deg); }
      50%  { transform: translate(-50%, -50%) scale(1.2) rotate(-7deg); }
      100% { transform: translate(-50%, -50%) scale(1) rotate(0deg); }
    }
  }

  .prize-text {
    position: absolute;
    top: 47%; left: 50%;
    transform: translateX(-50%);
    background: url(/i/stage-text-bg.png) no-repeat 50% / contain;
    width: 1170px;
    max-width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 3% 12% 4% 7%;
    box-sizing: border-box;
    text-align: center;

    @media $mobile {
      background-size: cover;
      padding-bottom: 6%;
      top: 48%;
    }

    @media $small-mobile {
      padding: 20px 10px;
      background: $blue;
      top: auto;
      bottom: 0;
    }
  }
}

.prize-section-3 {
  background: url(/i/prize-bg-3.jpg) no-repeat 50% / cover;

  @media $small-mobile {
    background-position: 50% 0;
    background-size: cover;
    background-color: $blue;
  }

  &:before {
    padding-bottom: 60%;

    @media $small-mobile {
      padding-bottom: 130%;
    }
  }

  .prize-caption {
    width: 57%;
    padding-bottom: 22%;
    position: absolute;
    left: 45%; top: 10%;
    z-index: 10;
    transform: translateX(-50%);
    background: url(/i/prize-caption-3-in.png) no-repeat 50% / contain;
    font-size: 0;

    @media $small-mobile {
      width: 120%;
      padding-bottom: 50%;
      left: 40%;
    }
  }

  .info-icon {
    width: 66px; height: 60px;
    background: url(/i/info-icon.png) no-repeat 50%;
    position: absolute;
    left: 87%; top: 6%;
    cursor: pointer;

    @media $small-mobile {
      width: 40px; height: 40px;
      background-size: contain;
    }
  }

  .prize-text {
    position: absolute;
    top: 33%; left: 50%;
    transform: translateX(-50%);
    background: url(/i/stage-text-bg.png) no-repeat 50% / contain;
    width: 1170px;
    max-width: 100%;
    padding: 0 11% 9% 6%;
    box-sizing: border-box;

    @media $mobile {
      padding-bottom: 20%;
      background-size: cover;
      top: 23%;
    }

    @media $not-small-mobile {
      display: flex;
      justify-content: space-around;
    }

    @media $small-mobile {
      padding: 20px 10px;
      top: 14%;
      background: $blue;

      br {
        display: none;
      }
    }

    .item {
      padding-top: 136px;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1.2;
      text-align: center;
      max-width: 220px;
      font-family: $caption-font;
      flex: 1;

      @media $small-mobile {
        max-width: 100%;

        + .item {
          margin-top: 20px;
        }
      }

      @media $not-small-mobile {
        + .item {
          margin-left: 20px;
        }
      }
    }

    .item-1 {
      background: url(/i/place-1.png) no-repeat 50% 0;
      font-size: 3.1rem;

      @media $mobile {
        font-size: 2rem;
      }
    }

    .item-2 {
      background: url(/i/place-2.png) no-repeat 50% 0;
      font-size: 1.7rem;

      @media $mobile {
        font-size: 1.5rem;
      }
    }

    .item-3 {
      background: url(/i/place-3.png) no-repeat 50% 0;
      font-size: 1.7rem;

      @media $mobile {
        font-size: 1.5rem;
      }
    }
  }

  .prize-hint {
    position: absolute;
    left: 0; bottom: 50px;
    font-size: 1.4rem;
    color: $black;

    @media only screen and (max-width: $wrapper-width + 20px) {
      left: 10px;
      color: $white;
    }

    @media $small-mobile {
      font-size: 1.2rem;
      bottom: 30px;
    }
  }
}

.prize-section-3-2 {
  background: url(/i/prize-bg-3-2.jpg) no-repeat 50% / cover;

  &:before {
    padding-bottom: 50%;
  }

  .place-2,
  .place-3 {
    width: 27%;
    padding-bottom: 19%;
    position: absolute;
    top: 21%;
    z-index: 10;

    .place-text {
      position: absolute;
      left: 0; top: 55%;
      box-sizing: border-box;
      width: 100%;
      color: $white;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
      font-family: $caption-font;
      font-size: 2.1rem;
      line-height: 1.2;

      @media only screen and (max-width: 1800px) {
        font-size: 1.7rem;
        padding: 0 7%;

        br {
          display: none;
        }
      }

      @media $notebook {
        font-size: 1.4rem;
      }

      @media $tablet {
        font-size: 1.2rem;
      }

      @media $mobile {
        span {
          background: $blue;
        }
      }
    }
  }

  .place-2 {
    background: url(/i/place-2-text-bg.png) no-repeat 50% / contain;
    right: 52%;
  }

  .place-3 {
    background: url(/i/place-3-text-bg.png) no-repeat 50% / contain;
    left: 52%;
  }

  .prize-item {
    position: absolute;

    a {
      font-size: 0;
      text-decoration: none;
      position: absolute;
      width: 146px; height: 131px;

      @media $mobile {
        width: 100px; height: 100px;
      }
    }
  }

  .prize-reusch {
    width: 30%;
    height: 56%;
    background: url(/i/reusch.png) no-repeat 50% / contain;
    left: 8%; top: -5%;
    z-index: 15;

    a {
      background: url(/i/reusch-link.png) no-repeat 50% / contain;
      right: 2%; top: 21%;

      @media $mobile {
        top: 10%;
      }
    }
  }

  .prize-ripcurl {
    width: 50%;
    height: 95%;
    background: url(/i/ripcurl.png) no-repeat 50% / contain;
    right: 8%; top: 5%;
    z-index: 5;

    a {
      background: url(/i/ripcurl-link.png) no-repeat 50% / contain;
      left: 36%; top: 1%;

      @media $mobile {
        top: -5%;
      }
    }
  }

  .prize-bolle {
    width: 34%;
    height: 59%;
    background: url(/i/bolle.png) no-repeat 50% / contain;
    left: 27%; bottom: -6%;
    z-index: 15;

    a {
      background: url(/i/bolle-link.png) no-repeat 50% / contain;
      left: 58%; top: -1%;
    }
  }

  @media $not-small-mobile {
    .mobile-inner {
      display: none;
    }
  }

  @media $small-mobile {
    background: #0097cf;

    &:before {
      display: none;
    }

    .inner {
      display: none;
    }
  }

  .mobile-inner {
    padding: 20px 10px;
    font-size: 1.4rem;
    font-family: $caption-font;
    font-weight: bold;
  }

  .mobile-prizes {
    position: relative;
    background: url(/i/mobile-prizes-2.jpg) no-repeat 50%;
    padding-bottom: 265%;
    margin-left: -10px;
    margin-right: -10px;

    .prize-item {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
      background: none;
      width: auto; height: auto;

      a {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .prize-ripcurl {
      top: 0;
    }

    .prize-reusch {
      top: 30%;
    }

    .prize-bolle {
      top: 55%;
    }
  }
}

.prize-section-4 {
  background: url(/i/prize-bg-4.jpg) no-repeat 50% / cover;

  @media $small-mobile {
    background-position: 70% 100%;
    background-size: 190% auto;
    background-color: $blue;
  }

  &:before {
    padding-bottom: 61.5%;
  }

  .prize-caption {
    width: 57%;
    padding-bottom: 20%;
    position: absolute;
    left: 45%; top: 14%;
    z-index: 10;
    transform: translateX(-50%);
    background: url(/i/prize-caption-4-in.png) no-repeat 50% / contain;
    font-size: 0;

    @media $mobile {
      top: 1%;
    }

    @media $small-mobile {
      width: 110%;
      padding-bottom: 37%;
      top: 4%;
      background-image: url(/i/prize-caption-4-in-m.png);
    }
  }

  .info-icon {
    width: 66px; height: 60px;
    background: url(/i/info-icon.png) no-repeat 50%;
    position: absolute;
    left: 84%; top: 8%;
    cursor: pointer;
  }

  .prize-text {
    position: absolute;
    top: 40%; left: 50%;
    transform: translateX(-50%);
    background: url(/i/stage-text-bg.png) no-repeat 50% / contain;
    width: 1170px;
    max-width: 100%;
    padding: 4% 11% 7% 7%;
    box-sizing: border-box;
    font-weight: bold;
    font-family: $caption-font;
    font-size: 2.4rem;
    line-height: 1.2;
    color: $white;
    text-align: center;


    @media $mobile {
      padding-bottom: 20%;
      background-size: cover;
      top: 28%;
    }

    @media $not-small-mobile {
      display: flex;
      justify-content: space-around;
    }

    @media $small-mobile {
      padding: 20px 10px;
      top: 12%;
      background: $blue;

      br {
        display: none;
      }
    }

    .item {
      padding-top: 136px;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1.2;
      text-align: center;
      max-width: 220px;
      font-family: $caption-font;
      flex: 1;

      @media $small-mobile {
        max-width: 100%;

        + .item {
          margin-top: 20px;
        }
      }

      @media $not-small-mobile {
        + .item {
          margin-left: 20px;
        }
      }
    }

    .item-1 {
      background: url(/i/place-1.png) no-repeat 50% 0;
      font-size: 1.7rem;

      @media $mobile {
        font-size: 1.5rem;
      }
    }

    .item-2 {
      background: url(/i/place-2.png) no-repeat 50% 0;
      font-size: 1.7rem;

      @media $mobile {
        font-size: 1.5rem;
      }
    }

    .item-3 {
      background: url(/i/place-3.png) no-repeat 50% 0;
      font-size: 1.7rem;

      @media $mobile {
        font-size: 1.5rem;
      }
    }
  }

  .prize-item {
    position: absolute;

    a {
      font-size: 0;
      text-decoration: none;
      position: absolute;
      width: 146px; height: 131px;

      @media $mobile {
        width: 100px; height: 100px;
      }
    }
  }

  .prize-sony {
    width: 30%;
    height: 51%;
    background: url(/i/sony.png) no-repeat 50% / contain;
    left: 30%; bottom: -4%;
    z-index: 15;

    a {
      background: url(/i/sony-link.png) no-repeat 50% / contain;
      left: 51%; top: 22%;
      width: 117px; height: 107px;

      @media $mobile {
        width: 80px; height: 80px;
      }
    }
  }
}

.prize-section-4-2 {
  background: url(/i/prize-bg-4-2.jpg) no-repeat 50% / cover;

  &:before {
    padding-bottom: 50%;

    @media $small-mobile {
      padding-bottom: 196%;
    }
  }

  .place-2,
  .place-3 {
    width: 27%;
    padding-bottom: 19%;
    position: absolute;
    top: 21%;
    z-index: 10;

    .place-text {
      position: absolute;
      left: 0; top: 55%;
      box-sizing: border-box;
      width: 100%;
      color: $white;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
      font-family: $caption-font;
      font-size: 2.1rem;
      line-height: 1.2;

      @media only screen and (max-width: 1800px) {
        font-size: 1.7rem;
        padding: 0 7%;

        br {
          display: none;
        }
      }

      @media $notebook {
        font-size: 1.4rem;
      }

      @media $tablet {
        font-size: 1.2rem;
      }

      @media $mobile {
        span {
          background: $blue;
        }
      }
    }
  }

  .place-2 {
    background: url(/i/place-2-text-bg.png) no-repeat 50% / contain;
    right: 52%;
  }

  .place-3 {
    background: url(/i/place-3-text-bg.png) no-repeat 50% / contain;
    left: 52%;
  }

  .prize-item {
    position: absolute;

    a {
      font-size: 0;
      text-decoration: none;
      position: absolute;
      width: 146px; height: 131px;

      @media $mobile {
        width: 100px; height: 100px;
      }
    }
  }

  .prize-reusch {
    width: 30%;
    height: 56%;
    background: url(/i/reusch.png) no-repeat 50% / contain;
    left: 8%; top: -5%;
    z-index: 15;

    a {
      background: url(/i/reusch-link.png) no-repeat 50% / contain;
      right: 2%; top: 21%;

      @media $mobile {
        top: 10%;
      }
    }
  }

  .prize-ripcurl {
    width: 50%;
    height: 95%;
    background: url(/i/ripcurl.png) no-repeat 50% / contain;
    right: 8%; top: 5%;
    z-index: 5;

    a {
      background: url(/i/ripcurl-link.png) no-repeat 50% / contain;
      left: 36%; top: 1%;

      @media $mobile {
        top: -5%;
      }
    }
  }

  .prize-bolle {
    width: 34%;
    height: 59%;
    background: url(/i/bolle.png) no-repeat 50% / contain;
    left: 12%; bottom: -16%;
    z-index: 15;

    a {
      background: url(/i/bolle-link.png) no-repeat 50% / contain;
      left: 58%; top: -1%;
    }
  }

  .prize-bolle-mask {
    width: 34%;
    height: 59%;
    background: url(/i/bolle-mask.png) no-repeat 50% / contain;
    right: 18%; bottom: -5%;
    z-index: 15;

    a {
      background: url(/i/bolle-link.png) no-repeat 50% / contain;
      left: 37%; top: 24%;
    }
  }

  .prize-sony {
    background: url(/i/sony.png) no-repeat 50% / contain;
    z-index: 15;

    a {
      background: url(/i/sony-link.png) no-repeat 50% / contain;
      left: 37%; top: 24%;
    }
  }

  @media $not-small-mobile {
    .mobile-inner {
      display: none;
    }
  }

  @media $small-mobile {
    background: #0097cf;

    &:before {
      display: none;
    }

    .inner {
      display: none;
    }
  }

  .mobile-inner {
    padding: 20px 10px;
    font-size: 1.4rem;
    font-family: $caption-font;
    font-weight: bold;
  }

  .mobile-prizes {
    position: relative;
    background: url(/i/mobile-prizes-3.jpg) no-repeat 50%;
    padding-bottom: 400%;
    margin-left: -10px;
    margin-right: -10px;

    .prize-item {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
      background: none;
      width: auto; height: auto;

      a {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .prize-sony {
      top: 57%;

      a {
        width: 80px; height: 80px;
      }
    }

    .prize-ripcurl {
      top: 0;
    }

    .prize-reusch {
      top: 21%;
    }

    .prize-bolle {
      top: 37%;
    }

    .prize-bolle-mask {
      top: 64%;
    }
  }
}

.prize-section-5 {
  background: url(/i/prize-bg-5.jpg) no-repeat 50% / cover;

  &:before {
    padding-bottom: 63%;

    @media $mobile {
      padding-bottom: 100%;
    }

    @media $small-mobile {
      padding-bottom: 170%;
    }
  }

  .prize-caption {
    width: 57%;
    padding-bottom: 20%;
    position: absolute;
    left: 45%; top: 17%;
    transform: translateX(-50%);
    background: url(/i/prize-caption-5-in.png) no-repeat 50% / contain;
    font-size: 0;

    @media $small-mobile {
      width: 100%;
      padding-bottom: 28%;
    }
  }

  .prize-text {
    position: absolute;
    top: 41%; left: 50%;
    transform: translateX(-50%);
    background: url(/i/stage-text-bg.png) no-repeat 50% / contain;
    width: 1170px;
    max-width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 4% 12% 7% 7%;
    box-sizing: border-box;
    text-align: center;

    @media $mobile {
      background-size: cover;
      padding-top: 7%;
      padding-bottom: 12%;
      top: 35%;
    }

    @media $small-mobile {
      background: rgba($blue, .75);
    }
  }
}

@media $not-small-mobile {
  .afterprize-rules .short-rules-section {
    margin-top: -180px;
  }
}

.popup.prize-hint {
  box-sizing: border-box;
  width: 860px;
  padding: 70px 60px;
  max-width: 100%;
  background: rgba($black, .75);
  font-weight: $medium;

  .popup--close {
    top: 35px; right: 35px;
  }

  @media $small-mobile {
    padding: 55px 15px 35px;

    p {
      font-size: 1.8rem;
    }

    .popup--close {
      top: 15px; right: 15px;
    }
  }
}