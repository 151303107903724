
/*   -=========== Layout ===========-   */

// Floats

.fl {
  float: left;
}

.fr {
  float: right;
}

.no-fl {
  float: none;
}

.l-main {
  float: left;
  width: 49%;
}

.r-main {
  float: right;
  width: 49%;
}


// Containers

.group, .nofloat {
  clearfix();
}

.no-overflow {
  overflow: hidden;
}

.wrapper {
  box-sizing: border-box;

  @media only screen and (min-width: $wrapper-width + 20px) {
    max-width: $wrapper-width;
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (max-width: $wrapper-width + 19px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.slide {
  box-sizing: border-box;
  height: 100vh;
  padding-top: $header-height;

  @media only screen and (orientation: portrait) {
    height: 50vh;
  }
}


// Margins

.m3 {
  margin-bottom: 3px;
}

.m7 {
  margin-bottom: 7px;
}

for $i in (0..20) {
  .m{$i * 5} {
    margin-bottom: 5px * $i;
  }
}


// Position

.relative {
  position: relative;
}

.static {
  position: static !important;
}


// Display

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.hidden {
  display: none !important;
}


// Devices display

@media $handheld {
  .only-desktop {
    display: none !important;
  }
}

@media $not-mobile {
  .only-mobile {
    display: none !important;
  }
}

@media $not-small-mobile {
  .only-small-mobile {
    display: none !important;
  }
}

@media $desktop {
  .not-desktop { // mobile + tablet
    display: none !important;
  }
}

@media $mobile {
  .not-mobile { // desktop + tablet
    display: none !important;
  }
}

@media $small-mobile {
  .not-small-mobile {
    display: none !important;
  }
}