
/*   -=========== Errors ===========-   */

.error-page {
  .error-video-bg {
    position: fixed;
    left: 0; top: 0;
    width: 100%; height: 100%;

    video {
      min-height: 100%;
      min-width: 100%;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .error-text {
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);

    @media $landscape-mobile {
      @media $not-small-mobile {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }
  }

  .error-code {
    font-family: $caption-font;
    font-weight: bold;
    font-size: 13.4rem;
    color: $green;
    white-space: nowrap;

    @media $mobile {
      font-size: 10rem;
    }

    @media $landscape-mobile {
      font-size: 5rem;
    }
  }

  .error-note {
    text-align: center;
    font-size: 2.6rem;
    font-weight: normal;
    margin-bottom: 30px;
    white-space: nowrap;

    @media $landscape-mobile {
      font-size: 1.6rem;
    }
  }

  .header--logo-group {
    position: relative;
    height: 100px;
    text-align: center;

    @media $mobile {
      transform: translateX(-50%);

      .header--logo {
        height: 100%;
        background-size: 91px 70px;
      }

      .header--ripcurl-logo:before {
        height: 100%;
        background-size: 15px 70px;
      }
    }

    @media $small-mobile {
      width: auto;
      margin-left: 0;

      .header--logo {
        width: 91px;
        margin-left: 0;
      }

      .header--ripcurl-logo {
        width: 91px;
      }
    }
  }

  .back-btn {
    padding: 0 45px 0 20px;

    &:hover {
      color: $green;

      &:before {
        border-color: $green;
      }

      .back-icon {
        &:before, &:after {
          background: $green;
        }
      }
    }
  }

  .error-socials {
    margin-top: 10%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;

    a {
      display: block;
      width: 25%;
      max-width: 92px;
      background-position: 50%;
      background-repeat: no-repeat;

      &:before {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      @media $mobile {
        background-size: contain;

        + a {
          margin-left: 10px;
        }
      }
    }

    for $social in $socials-list {
      .sm-{$social} {
        background-image: url('/i/sm-' + $social + '-green.png');
      }
    }
  }
}