
/*   -=========== Fonts ===========-   */

@font-face {
  font-family: 'intro_cond';
  src: url('/i/fonts/intro_cond_light_free-webfont.woff2') format('woff2'),
       url('/i/fonts/intro_cond_light_free-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'intro_cond';
  src: url('/i/fonts/intro_cond_black_free-webfont.woff2') format('woff2'),
       url('/i/fonts/intro_cond_black_free-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'San Francisco';
  font-weight: 100;
  src: url('/i/fonts/sf-ui-display-ultralight-webfont.woff2'),
       url('/i/fonts/sf-ui-display-ultralight-webfont.woff');
}

/** Thin */
@font-face {
  font-family: 'San Francisco';
  font-weight: 200;
  src: url('/i/fonts/sf-ui-display-thin-webfont.woff2'),
       url('/i/fonts/sf-ui-display-thin-webfont.woff');
}

/** Regular */
@font-face {
  font-family: 'San Francisco';
  font-weight: 400;
  src: url('/i/fonts/sf-ui-display-medium-webfont.woff2'),
       url('/i/fonts/sf-ui-display-medium-webfont.woff');
}

/** Medium */
@font-face {
  font-family: 'San Francisco';
  font-weight: normal;
  src: url('/i/fonts/sf-ui-display-regular-webfont.woff2'),
       url('/i/fonts/sf-ui-display-regular-webfont.woff');
}

/** Semi Bold */
@font-face {
  font-family: 'San Francisco';
  font-weight: 500;
  src: url('/i/fonts/sf-ui-display-semibold-webfont.woff2'),
       url('/i/fonts/sf-ui-display-semibold-webfont.woff');
}

/** Bold */
@font-face {
  font-family: 'San Francisco';
  font-weight: bold;
  src: url('/i/fonts/sf-ui-display-bold-webfont.woff2'),
       url('/i/fonts/sf-ui-display-bold-webfont.woff');
}