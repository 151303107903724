
/*   -=========== Popups ===========-   */

// Base

.popup {
  display: none;
  position: absolute;
  background: $body-bg;
  z-index: 333;
}

.popup--close,
.tooltip--close {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0; right: 0;
  z-index: 100;
  cursor: pointer;

  &:before, &:after {
    content: '';
    display: block;
    width: 31px; height: 2px;
    position: absolute;
    left: 50%; top: 50%;
    background: $white;
    cursor: pointer;
    margin-left: -15px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}


// Socials auth

.auth-popup {
  padding: 20px 55px 20px 35px;
}

.sm-auth {
  text-align: center;

  a {
    display: inline-block;
    vertical-align: middle;
    width: 50px; height: 50px;
    transition: opacity .25s ease;

    + a {
      margin-left: 20px;
    }

    &:hover {
      opacity: .75;
    }
  }

  $login-socials-list = fb, vk, twit;

  for $social in $login-socials-list {
    .sm-{$social} {
      background: url('/i/sm-' + $social + '-big.png') no-repeat 50% / contain;
    }
  }
}


// Vote popup

.confirmation-popup {
  padding: 30px;
}

.confirmation-form {
  width: 310px;

  .form-caption {
    margin-bottom 20px;
  }

  .btn {
    @extend .p20btn, .h50btn;
  }

  .form-submit {
    margin: 0;
  }

  .form-hint {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
}


// Float links

.float-link {
  display: none;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;

  a {
    display: block;
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
  }

  .close {
    display: block;
    width: 48px; height: 48px;
    position: absolute;
    cursor: pointer;
    background: url(/i/close.png) no-repeat 50%;
  }

  @media $landscape-mobile {
    @media $not-small-mobile {
      top: 30px;
      transform: none;
    }
  }
}

.hilux-link {
  width: 372px; height: 396px;
  left: 0;
  background: url(/i/hilux-link.png) no-repeat 0 0 / contain;

  .close {
    top: 25px; right: 25px;
  }

  @media $landscape-mobile {
    width: 40%;
  }

  @media $small-mobile {
    top: 50%;

    .close {
      top: 0; right: -20px;
    }
  }
}

.rav-link {
  width: 385px; height: 474px;
  right: 0;
  background: url(/i/rav-link.png) no-repeat 0 0 / contain;

  .close {
    top: 65px; left: 40px;
  }

  @media $landscape-mobile {
    width: 40%;
  }

  @media $small-mobile {
    top: 80%;

    .close {
      top: 0; left: 0;
    }
  }
}