
/*   -=========== Works ===========-   */

.works-section {
  width: 100%;
  position: relative;
  padding: 25px 0;

  /* &.stage-3 {
    display: none;
  } */

  &.sony-popup-section {
    display: none;
  }

  .popup--close {
    position: fixed;
    right: 20px; top: 20px;

    @media $handheld {
      right: 10px; top: 10px;
    }
  }
}

.works-section--bg {
  width: 100%; height: 100vh;
  position: absolute;
  left: 0; top: 0;
  z-index: -1;
  background: url(/i/bg-4.jpg) no-repeat 50% / cover;
}

.works-section--caption-line {
  text-align: center;
  position: relative;
  line-height: 50px;
  margin-bottom: 25px;

  @media $mobile {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    * {
      margin: 0 20px;
    }

    .rating-link {
      margin-top: 10px;
    }

    .mobile-spacer {
      width: 50%;
    }
  }

  @media $small-mobile {
    justify-content: center;
    margin-bottom: 40px;
  }

  @media $not-mobile {
    .back-link {
      position: absolute;
      left: 0; top: 50%;
      transform: translateY(-50%);

      @media only screen and (max-width: $wrapper-width + 20px) {
        left: 10px;
      }
    }

    .rating-link {
      position: absolute;
      right: 0; top: 50%;
      transform: translateY(-50%);

      @media only screen and (max-width: $wrapper-width + 20px) {
        right: 10px;
      }
    }
  }
}

.works-section--caption {
  font-family: $caption-font;
  font-size: 5rem;
  font-weight: bold;
  text-transform: uppercase;
}

.works-section--subcaption {
  font-family: $caption-font;
  font-size: 3.5rem;
  text-transform: uppercase;
}

.rating-link,
.works-link {
  @extend .btn, .white-dotted-btn, .caption-font, .bb, .uppercase, .s16, .h50btn, .p30btn;
}

.back-link {
  display: inline-block;
  color: $blue;
  font-size: 1.6rem;
  text-decoration: none;
  padding-left: 70px;
  transition: color .25s ease;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 70px; height: 100%;
    background: url(/i/left.png) no-repeat 0 50%;
    position: absolute;
    left: 0; top: 50%;
    transform: translateY(-50%);
    margin-top: -2px;
    transition: filter .25s ease;
  }

  &:hover {
    color: $white;

    &:before {
      filter: brightness(10);
    }
  }
}

.works-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 24px;

  .work {
    width: calc(25% - 32px);
    margin: 0 16px 40px;
    position: relative;
    background-position: 50%;
    background-size: cover;

    @media $notebook {
      width: calc(33.33% - 32px);
      /* padding-bottom: 17%; */
    }

    @media $handheld {
      width: calc(50% - 32px);
      /* padding-bottom: 26%; */
    }

    @media $small-mobile {
      width: 100%;
      /* padding-bottom: 52%; */
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 56%;
      position: relative;
      z-index: 5;
      background: rgba($black, .8);
      opacity: 0;
      transition: opacity .25s ease;
    }

    &.toyota:after {
      content: '';
      display: block;
      width: calc(100% + 35px); height: calc(100% + 30px);
      position: absolute;
      left: -25px; top: -15px;
      z-index: 10;
      background: url(/i/toyota-video-overlay.png) no-repeat 50% / contain;
      pointer-events: none;
    }
  }

  .work--inner {
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    text-align: center;
    opacity: 0;
    transition: opacity .25s ease;
  }

  .work:hover {
    &:before,
    .work--inner {
      opacity: 1;
    }
  }

  .work--userpic {
    height: 49px;
    position: relative;

    &:after {
      content: '';
      display: block;
      /* width: width('i/sign-border.svg'); */
      /* height: height('i/sign-border.svg'); */
      width: 100%; height: 100%;
      background: url(/i/sign-border.svg) no-repeat 50% / contain;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }

    a {
      display: inline-block;
      /* width: width('i/sign-clip.svg'); */
      width: 49px; height: 100%;
      clip-path: url(#clip-path);
      position: relative;
      overflow: hidden;

    }

    img {
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      max-width: none;
    }
  }

  .work--name {
    font-size: 2.4rem;
    line-height: 1.2;
    margin: 10px 10px 12px;

    a {
      text-decoration: none;
    }
  }

  .work--link {
    @extend .btn, .white-dotted-btn, .h50btn, .p30btn, .caption-font, .s18, .bb, .uppercase;
    white-space: nowrap;

    &:after {
      content: url(/i/play-icon-blue.png);
      display: inline-block;
      vertical-align: middle;
      margin: 0 -10px 0 20px;
      line-height: 0;
      filter: brightness(10);
      transition: filter .25s ease;
      transform: translateZ(0);
    }

    &:hover:after {
      filter: brightness(1);
    }
  }
}