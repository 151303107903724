
/*   -=========== News ===========-   */

$teaser-gutter = 60px;

.news-slide {
  position: relative;
  font-size: 0;
  background: $white;
  padding: 90px 0 100px;
  overflow: hidden;

  @media $small-mobile {
    padding: 30px 0 20px;
  }
}

.news-section--bg {
  width: 100%; height: 100vh;
  position: absolute;
  left: 0; top: 0;
  background: url(/i/bg-5.jpg) no-repeat 50% / cover;

  .is-collapsed & {
    position: absolute !important;
  }
}

.news-slide--inner {
  &, .items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

    @media $small-notebook {
      display: block;
    }

    @media $handheld {
      display: block;
    }

    /* @media $mobile {
      display: block;
    } */
  }
}

.news-section-caption {
  text-transform: uppercase;
  font-size: 1.7rem;
  font-weight: bold;
  text-align: center;
  height: 76px;
  line-height: 76px;
  width: 100%;

  &, a {
    color: $gray;
    text-decoration: none;
    transition: color .25s ease;
  }

  a:hover {
    color: $white;
  }
}

.news-columns-container {
  display: inline-flex;
  height: 100%;
  flex: 1;

  @media $small-mobile {
    flex-direction: column;
  }
}

.news-section {
  display: inline-flex;
  flex-direction: column;

  @media $small-notebook {
    display: flex;

    + .news-section {
      margin-top: 1rem;
    }
  }

  @media $mobile {
    display: flex;

    + .news-section {
      margin-top: 1rem;
    }
  }

  @media $small-mobile {
    + .news-section {
      margin-top: 0;
    }
  }
}

.socials-section {
  @media $small-notebook {
    box-sizing: border-box;
    width: 100%;
    padding: 0 45px;

    .news-column {
      width: 50%;

      + .news-column {
        margin-left: 20px;
      }
    }
  }

  @media $handheld {
    box-sizing: border-box;
    width: 100%;
    padding: 0 10px;

    .news-column {
      width: calc(50% - 20px) !important;
      padding: 0;
      min-width: 0;

      + .news-column {
        margin-left: 20px;
      }
    }
  }

  @media $small-mobile {
    padding: 0 10px;

    .news-column {
      width: 100% !important;

      + .news-column {
        margin-left: 0;
      }
    }
  }
}

.news-section .news-column {
  display: inline-block;
  vertical-align: top;
  width: calc(25vw - 60px);
  padding: 0 30px;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  margin: 0 auto;
  height: 100%;

  &.big {
    width: calc(42vw - 60px);
    padding: 0 40px 0 20px;

    @media $small-notebook {
      width: calc(100% - 40px);
      padding: 0 40px;
      margin-bottom: 40px;
    }

    @media $handheld {
      width: calc(100% - 40px) !important;
      padding: 0 40px !important;
      margin-bottom: 40px;
    }

    /* @media $mobile {
      width: calc(100% - 20px);
    } */
  }

  @media $not-mobile {
    min-width: 400px;
  }

  @media only screen and (max-width: 1300px) {
    width: calc(22vw - 40px);
    padding: 0 20px;
    min-width: 360px;
  }

  @media $landscape-tablet {
    width: calc(33vw - 10px) !important;
    min-width: 0 !important;
    padding: 0 5px !important;
  }

  @media $mobile {
    padding: 0 5px;
  }

  @media $small-notebook {
    min-height: 600px;
    width: calc(50vw - 30px);
  }

  @media $mobile {
    /* min-height: 600px; */
    width: calc(50vw - 30px);
  }

  @media $small-mobile {
    /* min-height: 400px; */
    min-width: 0;
    width: 100%;
    display: block;

    + .news-column {
      margin-top: 15px;
    }
  }

  .social-caption {
    display: block;
    height: 98px;
    line-height: 108px;
    text-decoration: none;
    text-align: center;
    color: $white;
    transition: background-color .25s ease;
    font-size: 1.5rem;
    position: relative;
    padding-left: 20px;
    box-sizing: border-box;

    @media $small-mobile {
      padding-left: 55px;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0; left: 0;
    }

    &.vk {
      background: #4e6f93;

      &:before {
        width: 90px; height: 100%;
        background: url(/i/icon-vk.png) no-repeat 50%;
      }

      &:hover {
        background: lighten(#4e6f93, 10);
      }
    }

    &.fb {
      background: #3d5a99;

      &:before {
        width: 80px; height: 100%;
        background: url(/i/icon-fb.png) no-repeat 50%;
      }

      &:hover {
        background: lighten(#3d5a99, 10);
      }
    }
  }

  .social-body {
    width: 100%;
    flex: 1;
    background: $white;
    position: relative;

    @media $small-notebook {
      min-height: 400px;
    }

    @media $handheld {
      min-height: 400px;
    }

    /* @media $small-mobile {
      min-height: 300px;
    } */
  }
}


/*   -=========== News announce ===========-   */

.news-announce {
  background: $white;
  padding-bottom: 80px;
  position: relative;
  height: 100%;
  flex: 1;

  &, a {
    color: $black;
  }

  @media $small-mobile {
    height: auto;
  }
}

.news-announce--pic {
  display: block;
  width: 100%; height: auto;
  line-height: 0;
  transform: translateZ(0);

  a {
    display: block;
    padding-bottom: 100%;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    transition: opacity .25s ease;
    transform: translateZ(0);

    .big & {
      padding-bottom: 48.7%;

      @media only screen and (max-width: 1300px) {
        padding-bottom: 100%;
      }
    }

    &:hover {
      opacity: .75;
    }
  }
}

.news-announce--inner {
  padding: 17px;
}

.news-announce--date {
  font-size: 1.4rem;
  text-align: center;
  height: 22px;
  line-height: 22px;
  padding-bottom: 17px;

  time {
    display: inline-block;
    position: relative;
    padding: 0 10px;

    &:before, &:after {
      content: '';
      display: block;
      position: relative;
      display: inline-block;
      width: 43px; height: 1px;
      position: absolute;
      top: 50%;
      background: $black;
    }

    &:before {
      right: 100%;
    }

    &:after {
      left: 100%;
    }
  }
}

.news-announce--caption {
  font-family: $caption-font;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.2;
  padding-bottom: 20px;
  text-transform: uppercase;
  text-align: center;

  a {
    text-decoration: none;

    &:hover {
      color: $gray;
    }
  }
}

.news-announce--text {
  font-size: 1.4rem;
  line-height: 1.2;
}

.news-announce--more {
  @extend .btn, .black-bordered-btn, .h50btn, .s14;
  position: absolute;
  bottom: 17px; left: 50%;
  transform: translateX(-50%);
  min-width: 200px;
  max-width: 100%;
}


/*   -=========== News page ===========-   */

.news-menu {
  background: $dark;
  padding: $teaser-gutter $teaser-gutter / 2;
  line-height: 0;

  li {
    box-sizing: border-box;
    width: 20%;
    padding: 0 $teaser-gutter / 2;
    text-align: center;

    @media $mobile-width {
      width: 100%;

      + li {
        margin-top: $teaser-gutter;
      }
    }
  }

  a {
    display: block;
    font-size: 0;
    position: relative;

    img {
      transition: opacity .25s ease;
    }

    img + img {
      position: absolute;
      top: 0; left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      height: 100%;
    }

    &:hover {
      img:first-child {
        opacity: .4;
      }

      img + img {
        opacity: 1;
      }
    }
  }
}

.news--announce {
  @media $not-mobile {
    @extend .group;
    display: flex;
    min-height: 387px;

    + .news--announce {
      .news--announce-body {
        border-top: 1px solid #d2d2d3;
      }
    }
  }
}

.news--announce-pic {
  display: block;
  line-height: 0;
  background: $dark;
  position: relative;

  @media $not-mobile {
    width: 50%;
  }

  .image {
    display: block;

    @media $not-mobile {
      width: 100%; height: 100%;
      position: absolute;
      top: 0; left: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
      transition: opacity .25s ease;

      img {
        display: none;
      }
    }
  }

  &.video-icon {
    &:after {
      content: '';
      width: 100%; height: 100%;
      position: absolute;
      top: 0; left: 0;
      background: url(/i/icon-play.png) no-repeat 50%;
      transition: opacity .25s ease;
    }
  }

  html:not(.is-mobile) &:hover {
    .image {
      opacity: .4;
    }

    &.video-icon:after {
      opacity: .8;
    }
  }
}

.news--announce-body {
  box-sizing: border-box;
  position: relative;

  @media $not-mobile {
    width: 50%;
    padding: 20px 30px 55px 45px;
  }

  @media $mobile {
    padding: 1.5rem 10px 2.5rem;
  }

  .datetime {
    display: block;
    font-size: 1rem;
    margin-bottom: 1rem;

    @media $mobile {
      text-align: center;
    }

    &:before, &:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 43px; height: 1px;
      background: $black;
    }

    &:before {
      margin-right: 10px;
    }

    &:after {
      margin-left: 10px;
    }
  }

  .h2 {
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 1rem;

    a {
      text-decoration: none;
    }

    @media $mobile {
      font-size: 1.7rem;
      text-align: center;
    }
  }

  .text-container {
    line-height: 1.5;
    margin-bottom: 1.7rem;
  }

  .news--announce-more {
    @media $mobile {
      text-align: center;
    }
  }

  .content-share-panel {
    @media $not-mobile {
      position: absolute;
      bottom: 12px; left: 30px;
    }

    @media $mobile {
      text-align: center;
      margin-top: 1rem;
    }
  }
}

.news--caption {
  background: $black;
  position: relative;
  line-height: 0;
}

.news--caption-image {
  opacity: .5;

  @media $mobile {
    display: none;
  }
}

.news--caption-image-mobile {
  @media $mobile {
    width: 100%; height: 100%;
    position: absolute;
    top: 0; left: 0;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    opacity: .5;
  }
}

.news--caption-text {
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  color: $white;
  width: 60%;
  max-width: $wrapper-width;
  text-align: center;

  @media $mobile {
    width: 90%;
    position: relative;
    top: 0;
    transform: translateX(-50%);
    padding: 10px 0;
  }

  @media $small-mobile {
    box-sizing: border-box;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .h1 {
    font-size: 2.5rem;
    line-height: 1.2;
    font-weight: normal;

    @media $mobile-width {
      font-size: 1.8rem;
    }

    @media $small-mobile {
      font-size: 1.7rem;
    }
  }

  .datetime {
    display: block;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
    margin-top: 1rem;

    &:before, &:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 43px; height: 1px;
      background: $white;
    }

    &:before {
      margin-right: 10px;
    }

    &:after {
      margin-left: 10px;
    }
  }
}

.news--body {
  padding: 85px 0 40px;
  background: url(/i/ripcurl-separator.png) no-repeat 50% 25px;
  line-height: 1.5;
}

.news--blocks-container {
  font-size: 0;

  .news--block {
    font-size: 1rem;
    line-height: 1.5;
  }
}

.news--block {
  box-sizing: border-box;

  &:not(.text) {
    text-align: center;
  }

  &:not(.full-width) {
    @media $not-mobile {
      display: inline-block;
      vertical-align: top;
      width: 50%;

      &:nth-child(odd) {
        padding-right: 5px;
      }

      &:nth-child(even) {
        padding-left: 5px;
      }

      &.text {
        &:nth-child(odd) {
          padding-right: 10px;
        }
      }

      &:nth-child(2) ~ .news--block {
        margin-top: 50px;
      }
    }

    @media $mobile {
      + .news--block {
        margin-top: 50px;
      }
    }
  }

  &.full-width ~ .news--block {
    margin-top: 50px;
  }

  @media $not-mobile {
    .video {
      margin-left: 10px;
      margin-bottom: 10px;
    }
  }
}

.gallery-caption {
  margin-top: 5px;
  text-align: center;

  span {
    display: inline-block;
    padding: 0 60px;
    position: relative;

    &:before, &:after {
      content: '';
      display: block;
      width: 43px; height: 1px;
      position: absolute;
      top: .8em;
      background: $black;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }
}


.news-list {
  display: flex;
  justify-content: flex-start;
  padding: 0 10px;

  &, .items {
    justify-content: flex-start;
  }

  .news-column {
    display: inline-block;
    vertical-align: top;
    width: 20%;
    padding: 0 10px;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 20px;

    &.big {
      width: 40%;
    }

    @media only screen and (max-width: 1300px) {
      width: 33.33% !important;

      .news-slide:not(.news-page) & {
        &:nth-child(3) ~ .news-column {
          display: none;
        }
      }
    }

    @media $landscape-tablet {
      width: 50% !important;

      .news-slide:not(.news-page) & {
        &:nth-child(2) ~ .news-column {
          display: none;
        }
      }
    }

    @media $mobile {
      width: 50% !important;
      padding: 0 5px;

      .news-slide:not(.news-page) & {
        &:nth-child(2) ~ .news-column {
          display: none;
        }
      }
    }

    @media $small-mobile {
      width: 100% !important;
      padding: 0 5px;

      .news-slide:not(.news-page) & {
        + .news-column {
          display: none;
        }
      }
    }
  }
}

.news-link {
  @extend .btn, .white-dotted-btn, .caption-font, .bb, .uppercase, .s16, .h50btn, .p30btn;
}

.news-item-inner {
  position: relative;
  /* background: url(/i/news-top.png) no-repeat 50% 0; */
  padding-top: 120px;

  .news-slide--caption {
    color: $black;
  }
}

.news-item {
  padding-top: 0;

  .news--top {
    /* margin-bottom: 120px; */
    margin-bottom: 50px;
    /* min-height: 220px; */
    padding-left: 10px;
    padding-right: 10px;
  }

  .news--date {
    display: block;
    font-size: 3.5rem;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 20px;

    span {
      display: inline-block;
      position: relative;

      &:before, &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 45px; height: 1px;
        background: $white;
      }

      &:before {
        margin-right: 10px;
      }

      &:after {
        margin-left: 10px;
      }
    }
  }

  .news--caption {
    font-family: $caption-font;
    font-size: 3.2rem;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    max-width: 690px;
    margin-left: auto;
    margin-right: auto;
    background: none;
    margin-bottom: 15px;
  }

  .news--subcaption {
    font-family: $caption-font;
    font-size: 2.2rem;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    max-width: 690px;
    margin-left: auto;
    margin-right: auto;
  }

  .text-container {
    font-size: 2rem;
    line-height: 1.2;
    padding-left: 10px;
    padding-right: 10px;
  }

  .text-wrapper {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }

  .illustration {
    text-align: center;

    .inner {
      display: inline-block;
      text-align: left;
    }

    img {
      margin-bottom: 15px;

      + div {
        margin-top: 0;
      }
    }
  }

  .video-caption {
    font-family: $caption-font;
    font-size: 3rem;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 20px;
    text-align: center;
  }

  .video-screen {
    display: block;
    padding-bottom: 56%;
    position: relative;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;

    &:hover {
      .video-link {
        background: $white;
        color: $blue;

        &:after {
          background-image: url(/i/video-play-blue.png);
        }
      }
    }
  }

  .video-link {
    @extend .btn, .blue-btn, .h62btn, .uppercase, .s26, .bb, .caption-font;
    letter-spacing: 3px;
    padding: 0 88px 0 38px;
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;

    @media $small-mobile {
      font-size: 1.3rem;
      letter-spacing: 1px;
    }

    &:after {
      content: '';
      display: block;
      width: 88px; height: 100%;
      background: url(/i/video-play-white.png) no-repeat 50%;
      position: absolute;
      right: 0; top: 0;
    }

    &:hover:after {
      background-image: url(/i/video-play-blue.png);
    }
  }
}

.news--share-block {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media $mobile {
    flex-direction: column;
  }
}

.news--share {
  background: transparent;
  border: 2px dotted $white;
  margin-bottom: 0 !important;

  @media $small-mobile {
    width: auto !important;
    margin-left: 0 !important;
  }
}

.news--share-caption {
  display: inline-block;
  vertical-align: middle;
}

.news--socials-caption {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.8rem;
  margin-right: 20px;

  @media $small-mobile {
    font-size: 1.4rem;
    display: block;
  }
}

.other-news-caption {
  @media $small-mobile {
    font-size: 3rem !important;
  }
}